import { IBaseCampaignDto, IContentDetailJson, ICreativeDto } from '@Workspace/_generated/interfaces';
import { Constants } from '@Workspace/common';
import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'content-component',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
    constructor(){
        
    }

    @Input() creative: ICreativeDto;
    @Input() campaign: IBaseCampaignDto;
    @Input() reviewPage: boolean = false;

    otherSportsInput: string = '';

    numberOfOrganicPostOptions: SelectItem[];
    approvedAthleticsOptions: SelectItem[];
    numberOfImpressionsOptions: SelectItem[];
    sportOptions: SelectItem[];
    producerOptions: SelectItem[];
    includedCheckboxes: SelectItem[];
    brandObjectiveCheckboxes: SelectItem[];

    contentProducerArray: string[] = [];
    includedArray: string[] = [];
    brandObjectiveArray: string[] = [];

    selectedSports: string[] = [];
    selectedProducers: string[] = [];

    isNAchecked: boolean = false;

    constants = Constants;


    ngOnInit() {
        if(!this.creative.contentDetailJson){
            this.initContentDetailJson();
        }
        else{
            this.fillLists();
        }
        this.fillDropdowns();
        this.fillCheckboxes();
    }

    initContentDetailJson(){
        this.creative.contentDetailJson = {} as IContentDetailJson;
        this.creative.contentDetailJson.sports = null;
        this.creative.contentDetailJson.contentProducer = null;
        this.creative.contentDetailJson.included = null;
        this.creative.contentDetailJson.brandObjectives = null;
        this.creative.contentDetailJson.description = null;
        this.creative.contentDetailJson.athleticsApproved = null;
        this.creative.contentDetailJson.numberOfOrganicPosts = null;
        this.creative.contentDetailJson.numberOfImpressions = null;
    }

    fillLists(){
        this.selectedSports = this.creative.contentDetailJson.sports != null ? this.creative.contentDetailJson.sports.split(',') : [];
        if(this.creative.contentDetailJson.sports != null && this.creative.contentDetailJson.sports.includes('Other')){
            for(var i = 0; i < this.selectedSports.length; i++){
                if(this.selectedSports[i].includes('Other')){
                    this.otherSportsInput = this.selectedSports[i].split(':')[1];
                    this.selectedSports[i] = 'Other';
                    return;
                }
            }
        }
        this.selectedProducers = this.creative.contentDetailJson.contentProducer != null ? this.creative.contentDetailJson.contentProducer.split(',') : [];
        this.brandObjectiveArray = this.creative.contentDetailJson.brandObjectives != null ? this.creative.contentDetailJson.brandObjectives.split(',') : [];
        this.includedArray = this.creative.contentDetailJson.included != null ? this.creative.contentDetailJson.included.split(',') : [];
        if(this.includedArray.includes('N/A'))
            this.isNAchecked = true;
    }

    fillDropdowns(){
        this.numberOfOrganicPostOptions = [
            {
                label: '-',
                value: null
            },
            {
                label: 'Not Applicable',
                value: '0'
            },
            {
                label: '1',
                value: '1'
            },
            {
                label: '2',
                value: '2',
            },
            {
                label: '3',
                value: '3'
            },
            {
                label: '4',
                value: '4',
            },
            {
                label: '5',
                value: '5'
            },
            {
                label: '6',
                value: '6',
            },
            {
                label: '7',
                value: '7'
            },
            {
                label: '8',
                value: '8',
            },
            {
                label: '9',
                value: '9'
            },
            {
                label: '10',
                value: '10',
            },
            {
                label: '11',
                value: '11'
            },
            {
                label: '12',
                value: '12',
            },
            {
                label: '13',
                value: '13'
            },
            {
                label: '14',
                value: '14',
            },
            {
                label: '15',
                value: '15'
            },
            {
                label: 'More than 15',
                value: 'More than 15',
            }
        ];

        this.approvedAthleticsOptions = [
            {
                label: '-',
                value: null
            },
            {
                label: 'Yes',
                value: 'Yes'
            },
            {
                label: 'No',
                value: 'No',
            },
            {
                label: 'Unsure',
                value: 'Unsure'
            }
        ];

        this.numberOfImpressionsOptions = [
            {
                label: '-',
                value: null
            },
            {
                label: 'No Guarantee',
                value: 'No Guarantee'
            },
            {
                label: '100,000',
                value: '100,000',
            },
            {
                label: '250,000',
                value: '250,000'
            },
            {
                label: '500,000',
                value: '500,000',
            },
            {
                label: '1,000,000',
                value: '1,000,000'
            },
            {
                label: 'More than 1,000,000',
                value: 'More than 1,000,000',
            }
        ];
   
        this.sportOptions = [
            {
                label: 'Multi-Sport',
                value: 'Multi-Sport'
            },
            {
                label: 'Football',
                value: 'Football'
            },
            {
                label: 'Men\'s Basketball',
                value: 'Men\'s Basketball',
            },
            {
                label: 'Women\'s Basketball',
                value: 'Women\'s Basketball'
            },
            {
                label: 'Baseball',
                value: 'Baseball',
            },
            {
                label: 'Softball',
                value: 'Softball'
            },
            {
                label: 'Women\'s Volleyball',
                value: 'Women\'s Volleyball'
            },
            {
                label: 'Unknown',
                value: 'Unknown'
            },
            {
                label: 'Other',
                value: 'Other'
            }
        ];

        this.producerOptions = [
            {
                label: 'Social+/Always On',
                value: 'Social+/Always On'
            },
            {
                label: 'Athletics',
                value: 'Athletics',
            },
            {
                label: 'Centralized LEARFIELD Studios',
                value: 'Centralized LEARFIELD Studios',
            },
            {
                label: 'Other',
                value: 'Other'
            },
            {
                label: 'Unknown',
                value: 'Unknown',
            }
        ];
    }

    fillCheckboxes(){
        this.includedCheckboxes = [
            {
                label: 'N/A',
                value: 'N/A'
            },
            {
                label: 'NIL',
                value: 'NIL'
            },
            {
                label: 'Paid Media (Requires Add-On Inventory + Social Hub submission)',
                value: 'Paid Media (Requires Add-On Inventory + Social Hub submission)',
            },
            {
                label: 'Fan365',
                value: 'Fan365'
            }
        ];

        this.brandObjectiveCheckboxes = [
            {
                label: 'Brand Awareness',
                value: 'Brand Awareness'
            },
            {
                label: 'Brand Consideration',
                value: 'Brand Consideration'
            },
            {
                label: 'Customer Retention',
                value: 'Customer Retention'
            },
            {
                label: 'Custom Upsell',
                value: 'Custom Upsell'
            },
            {
                label: 'Website Traffic',
                value: 'Website Traffic'
            },
            {
                label: 'In-Store Sales',
                value: 'In-Store Sales'
            },
            {
                label: 'Lead Generation',
                value: 'Lead Generation'
            },
            {
                label: 'Make Good',
                value: 'Make Good'
            },
        ]
    }

    onChangeCheckbox(event: any, value: string, checkboxName: string){
        if(event.checked){
            if(checkboxName=='included'){
                
                if(value == 'N/A'){
                    this.isNAchecked = true;
                    this.includedArray = [];
                    this.includedArray.push(value);
                    this.creative.contentDetailJson.included = this.includedArray.toString();
                }
                else if(!this.includedArray.includes(value)){
                    this.includedArray.push(value);
                    this.creative.contentDetailJson.included = this.includedArray.toString();
                }
            }
            else if(checkboxName=='brandObjectives'){
                if(!this.brandObjectiveArray.includes(value)){
                    this.brandObjectiveArray.push(value);
                    this.creative.contentDetailJson.brandObjectives = this.brandObjectiveArray.toString();
                }
            }
        }
        else{
            if(checkboxName=='included'){
                if(value == 'N/A'){
                    this.isNAchecked = false;
                    this.includedArray = [];
                }
                else{
                    this.includedArray = this.includedArray.filter(x => x != value);
                    this.creative.contentDetailJson.included = this.includedArray.toString();
                }

            }
            else if(checkboxName=='brandObjectives'){
                this.brandObjectiveArray = this.brandObjectiveArray.filter(x => x != value);
                this.creative.contentDetailJson.brandObjectives = this.brandObjectiveArray.toString();
            }
        }
    }

    isSelected(contentDetail: string[], value: string){
        return contentDetail != null ? contentDetail.includes(value) : false;
    }

    onChangeOtherField(contentDetail: string){
        if(contentDetail == 'sports'){
            this.creative.contentDetailJson.sports = this.selectedSports.toString();
            this.creative.contentDetailJson.sports = this.creative.contentDetailJson.sports.replace('Other','Other:' + this.otherSportsInput);
        }
    }

    onMultiSelectChange(type: string){
        if(type == 'sports'){
            this.creative.contentDetailJson.sports = this.selectedSports.toString();
        }
        else if(type == 'producers'){
            this.creative.contentDetailJson.contentProducer = this.selectedProducers.toString();
        }

    }
}
