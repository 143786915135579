import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { IChannelJson, ICreativeDto, IBaseCampaignDto, ICreativeAssetDto, IEnumDataAttribute, ICreativeDetailsDto } from '@Workspace/_generated/interfaces';
import { eStandardCampaginChannel, eChannelType, eStandardCampaginChannelName, eEntityStatus } from '@Workspace/_generated/enums';
import { MessageService, SelectItem } from 'primeng/api';
import { MultiSelectItem } from 'primeng/multiselect';
import { LookUps, Constants, Functions } from '@Workspace/common';
import { AccountController, CampaignController } from '@Workspace/_generated/services';
import { CampaignService } from '../../../../services/campaign.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { emitKeypressEvents } from 'readline';
import { getuid } from 'process';
import { PageLoaderService } from '@Workspace/services';
import { AuthService } from '@Workspace/auth';


@Component({
    selector: 'su-campaign-detail-channels',
    templateUrl: 'su-campaign-channels.component.html',
    styleUrls: ['./su-campaign-channels.component.scss']


})
export class SuCampaignChannelComponent implements OnInit {

    constructor(public router: Router,
        public authService: AuthService, public campaignService: CampaignService, protected fb: UntypedFormBuilder, public accountController: AccountController, public campaignController: CampaignController, public activatedRoute: ActivatedRoute, private pageLoaderService: PageLoaderService, private messageService: MessageService,) { }

    inputNumber: any = 0;
    formGroup: UntypedFormGroup;
    SelectedCampaignList: any[] = [];
    @Output() onStepTwo: EventEmitter<any> = new EventEmitter();
    creativeDto: ICreativeDto[] = {} as ICreativeDto[];
    @Input() creativeDtoFromPreviousScreen: ICreativeDto[] = [];
    @Input() isBackButtonActivated: boolean = false;
    @Input() campaign: IBaseCampaignDto = {} as IBaseCampaignDto;
    @Input() startDate: Date;
    @Input() endDate: Date;

    channelTypes: SelectItem[] = [];
    currentUser = this.authService.userSettings;
    campaignId: any;
    ngUnsubscribe = new Subject();
    creativeId: any;
    creativesFromMap: any;
    socialCreatives = [];
    displayCreatives = [];
    otherCreatives = [];
    creatives: ICreativeDto[] = [] as ICreativeDto[];
    selectedBannerDimensions = [];

    async ngOnInit() {
        this.creativesFromMap = new Map<number, IEnumDataAttribute>([...eStandardCampaginChannelName]);

        this.channelTypes = LookUps.getChannelTypesLu();
        this.creativeDto = [];
        const creatives = this.enumMapToArray(this.creativesFromMap)

        creatives.forEach(element => {
            if (!!element.channelId) {

                if (element.channelId == 1) {
                    this.socialCreatives.push(element)
                }
                if (element.channelId == 2 && element.value != eStandardCampaginChannel.OAS_Display && element.value != eStandardCampaginChannel.OAS_Video) {
                        this.displayCreatives.push(element)
                }
                if (element.channelId == 3) {

                    this.otherCreatives.push(element)

                }

            }
        });

        await this.channelTypes.map(x => {
            if (this.creativeDtoFromPreviousScreen.length == 0) {
                let channelOptions = {} as ICreativeDto;
                // channelOptions.creatives = [];
                channelOptions.creativeTypeId = x.value;
                channelOptions.displayName = x.label;
                channelOptions.channelId = 0;
                channelOptions.quantity = 0;
                channelOptions.name = '';
                if (!!this.creativeDto)
                    this.creativeDto.push(channelOptions);
            }
        });

        this.formInit()


        // this.formInit()
    }
    private enumMapToArray(map: Map<number, IEnumDataAttribute>): any[] {
        let result: any[] = [];
        map.forEach(((enumDataAttr, key) => {

            if (enumDataAttr.name != "Premium Display Banner")
                result.push({ label: enumDataAttr.name, value: key, channelId: enumDataAttr.channelId, quantity: 0 })
        }
        ));


        return result;

    }

    setCreativeQuantity(event, value) {

        this.creativeDto.find(x => x.creativeTypeId == value).quantity = event;
    }

    formInit() {
        this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.campaignId = params.slug;
        });
        let filter = `?$filter=Id eq ${this.campaignId}`;
        this.campaignController.GetAllCampaigns(filter).subscribe(async data => {
            if (data.totalCount > 0) {

                this.campaign = data.data[0];
                this.channelTypes = LookUps.getChannelTypesLu();
                this.creativeDto = [];


                if (this.campaign.creatives.length) {

                    // creativeOptions.creativeAssets = this.campaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active).find(y => y.creativeTypeId == x.value) ? this.campaign.creatives.find(cr => cr.creativeTypeId == x.value).creativeAssets : [];
                    // creativeOptions.bannerDimensions = [];

                    this.campaign.creatives.forEach(y => {
                        var dimensions = [];
                        let creativeOptions = {} as ICreativeDto;
                        creativeOptions.creativeTypeId = y.creativeTypeId;
                        creativeOptions.displayName = y.displayName;
                        creativeOptions.name = y.name;
                        creativeOptions.id = y.id;
                        creativeOptions.flightEndDate = y.flightEndDate;
                        creativeOptions.flightStartDate = y.flightStartDate;
                        creativeOptions.flightEndLocalDate = new Date(y.flightEndDate.toString());
                        creativeOptions.flightStartLocalDate = new Date(y.flightStartDate.toString());
                        if (y.channelId == eChannelType.Display) {
                            y.creativeAssets.filter(x => x.entityStatus == eEntityStatus.Active).forEach(y => {
                                dimensions.push(y.width + "x" + y.height)
                            });
                            creativeOptions.isDisplay = true;
                        }
                        creativeOptions.bannerDimensions = dimensions;
                        creativeOptions.creativeAssets = y.creativeAssets;
                        creativeOptions.detailJson = y.detailJson;
                        creativeOptions.isBuilt = y.isBuilt;
                        creativeOptions.quantity = !!this.campaign.creatives.find(c => c.creativeTypeId == y.creativeTypeId) ? this.campaign.creatives.filter(c => c.creativeTypeId == y.creativeTypeId).length : 0;

                        this.creativeDto.push(creativeOptions);

                        if (this.displayCreatives.some(x => x.value == creativeOptions.creativeTypeId)) {
                            this.displayCreatives.find(x => x.value == creativeOptions.creativeTypeId).quantity = creativeOptions.quantity;
                        }
                        if (this.socialCreatives.some(x => x.value == creativeOptions.creativeTypeId)) {
                            this.socialCreatives.find(x => x.value == creativeOptions.creativeTypeId).quantity = creativeOptions.quantity;
                        }
                        if (this.otherCreatives.some(x => x.value == creativeOptions.creativeTypeId)) {
                            this.otherCreatives.find(x => x.value == creativeOptions.creativeTypeId).quantity = creativeOptions.quantity;
                        }
                    });
                }

                await this.channelTypes.map(x => {
                    this.creativeDto.filter(c => c.creativeTypeId == x.value).forEach(c => c.displayName = x.label)
                    if (!this.creativeDto.some(c => c.creativeTypeId == x.value)) {
                        let creativeOptions = {} as ICreativeDto;
                        creativeOptions.creativeTypeId = x.value;
                        creativeOptions.displayName = x.label;
                        creativeOptions.bannerDimensions = [];
                        this.creativeDto.push(creativeOptions);
                    }
                });

                this.creativeDto = this.creativeDto.sort((a, b) => a.creativeTypeId - b.creativeTypeId);

                this.selectedBannerDimensions = [...new Set([...this.creativeDto[eStandardCampaginChannel.Standard_Display_Banner].bannerDimensions, ...this.creativeDto[eStandardCampaginChannel.Abandoned_Cart_Display_Banner].bannerDimensions, ...this.creativeDto[eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner].bannerDimensions, ...this.creativeDto[eStandardCampaginChannel.Premium_Display_Banner].bannerDimensions])];
            }
        },
        error => {
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
        });

        if (this.campaignId == Constants.GuidEmpty) {
            this.creativeDto = [];

            this.channelTypes.map(x => {
                let creativeOptions = {} as ICreativeDto;
                creativeOptions.id = x.value
                creativeOptions.creativeTypeId = x.value;
                creativeOptions.displayName = x.label;
                creativeOptions.quantity = 0;
                creativeOptions.name = '';
                creativeOptions.isBuilt = false;
                this.creativeDto.push(creativeOptions);
            });

            if (!!this.creativeDtoFromPreviousScreen && this.creativeDtoFromPreviousScreen.length > 0) {
                // let merged = { ...this.creativeDto, ...this.creativeDtoFromPreviousScreen };
                // this.creativeDto = merged;
                for (var c of this.creativeDtoFromPreviousScreen) {
                    if (this.displayCreatives.some(x => x.value == c.creativeTypeId)) {
                        this.displayCreatives.find(x => x.value == c.creativeTypeId).quantity = c.quantity;
                    }
                    if (this.socialCreatives.some(x => x.value == c.creativeTypeId)) {
                        this.socialCreatives.find(x => x.value == c.creativeTypeId).quantity = c.quantity;
                    }
                    if (this.otherCreatives.some(x => x.value == c.creativeTypeId)) {
                        this.otherCreatives.find(x => x.value == c.creativeTypeId).quantity = c.quantity;
                    }

                }

            }
            // else {
            //     this.creativeDto = [];

            //     this.channelTypes.map(x => {
            //         let creativeOptions = {} as ICreativeDto;
            //         creativeOptions.creativeTypeId = x.value;
            //         creativeOptions.displayName = x.label;
            //         creativeOptions.channelId = 0;
            //         creativeOptions.name = '';
            //         creativeOptions.quantity = 0;
            //         creativeOptions.creativeDetailsDtos = [];
            //         this.creativeDto.push(creativeOptions);
            //     })

            // }
        }
    }

    OnNextStep() {
        if(!!this.startDate && !!this.endDate){
            let creativeDto = Object.values(this.creativeDto);
            this.creativeDto = creativeDto;
            this.socialCreatives.forEach(x => {
                this.creativeDto.find(c => c.creativeTypeId == x.value).quantity = x.quantity;
            })
            this.displayCreatives.forEach(x => {
                this.creativeDto.find(c => c.creativeTypeId == x.value).quantity = x.quantity;
            })
            this.otherCreatives.forEach(x => {
                this.creativeDto.find(c => c.creativeTypeId == x.value).quantity = x.quantity;
            })

            this.creativeDto.forEach(element => {
                let campaignCreativeCount = !!this.campaign.creatives ? this.campaign.creatives.filter(y => y.creativeTypeId == element.creativeTypeId).length : 0;

                if (campaignCreativeCount > element.quantity) {
                    let diff = campaignCreativeCount - element.quantity;

                    for (var i = 0; i < diff; i++) {
                        let indexToRemove = this.creativeDto.findIndex(x => x.creativeTypeId == element.creativeTypeId);
                        creativeDto.splice(indexToRemove, 1);
                    }
                }
                else if (campaignCreativeCount < element.quantity) {
                    let diff = element.quantity - campaignCreativeCount;

                    for (var i = 0; i < diff; i++) {
                        let creative = {} as ICreativeDto;
                        creative.name = '';
                        creative.displayName = element.displayName;
                        creative.creativeTypeId = element.creativeTypeId;
                        creative.flightEndDate = this.campaign.endDate;
                        creative.flightStartDate = this.campaign.startDate;
                        creative.flightEndLocalDate = this.endDate;
                        creative.flightStartLocalDate = this.startDate;
                        creative.isBuilt = element.isBuilt;
                        creativeDto.push(creative);
                        creative.quantity = diff;
                    }
                }

            });

            this.creativesFromMap = new Map<number, IEnumDataAttribute>([...eStandardCampaginChannelName]);
            const creatives = this.enumMapToArray(this.creativesFromMap)

            creativeDto.forEach(element => {
                if (element.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner) {

                    element.channelId = eChannelType.Display;
                    element.isDisplay = true;
                }

                element.channelId = creatives.find(x => x.value == element.creativeTypeId) ? creatives.find(x => x.value == element.creativeTypeId).channelId : 0;

                if (element.quantity > 0) {
                    for (var x = 0; x < element.quantity; x++) {

                        if (!!element.creativeAssets && !!element.creativeAssets.length) {
                            continue;
                        }

                        element.creativeAssets = []
                        if (element.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel) {

                            for (var i = 0; i < 3; i++) {

                                const creativeAssetsDto = {} as ICreativeAssetDto;
                                creativeAssetsDto.id = Constants.GuidEmpty;
                                element.creativeAssets.push(creativeAssetsDto);
                            }
                        }
                        else if (element.creativeTypeId == eStandardCampaginChannel.Native_Image || element.creativeTypeId == eStandardCampaginChannel.Native_Video || element.creativeTypeId == eStandardCampaginChannel.Spotify_Audio || element.creativeTypeId == eStandardCampaginChannel.Spotify_Video) {

                            for (var i = 0; i < 2; i++) {

                                const creativeAssetsDto = {} as ICreativeAssetDto;
                                creativeAssetsDto.id = Constants.GuidEmpty;
                                element.creativeAssets.push(creativeAssetsDto);
                            }
                        }
                        else if (element.creativeTypeId == eStandardCampaginChannel.Instant_Experience) {

                            for (var i = 0; i < 4; i++) {

                                const creativeAssetsDto = {} as ICreativeAssetDto;
                                creativeAssetsDto.id = Constants.GuidEmpty;
                                element.creativeAssets.push(creativeAssetsDto);
                            }
                        }
                        else if (element.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed) {

                            for (var i = 0; i < 2; i++) {

                                const creativeAssetsDto = {} as ICreativeAssetDto;
                                creativeAssetsDto.id = Constants.GuidEmpty;
                                element.creativeAssets.push(creativeAssetsDto);
                            }
                        }
                        else {
                            const creativeAssetsDto = {} as ICreativeAssetDto;
                            creativeAssetsDto.id = Constants.GuidEmpty;
                            element.creativeAssets.push(creativeAssetsDto);
                        }
                    }
                }
            });
        }
        this.onStepTwo.emit({ creativeDto: this.creativeDto.filter(x => !!x.quantity && !!x.flightStartDate && !!x.flightEndDate), dimensions: this.selectedBannerDimensions });
    }

    isNextButtonEnabled(){
        return this.otherCreatives.some(x => x.quantity) || this.socialCreatives.some(x => x.quantity) || this.displayCreatives.some(x => x.quantity);
    }

    Cancel() {
        var gridId = 2; // HS campaigns
        this.campaignService.setGridIdId(gridId);
        this.router.navigate(['/mogo']);
    }
}