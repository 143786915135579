//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { SettingsService } from '@Workspace/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILoginModel } from './interfaces';
import { IRefreshTokenModel } from './interfaces';
import { ITimeZoneDto } from './interfaces';
import { IPagingResult } from './interfaces';
import { IUserDto } from './interfaces';
import { IUserStatisticsDto } from './interfaces';
import { IUserPropertiesDto } from './interfaces';
import { IAccountDto } from './interfaces';
import { ISampleResponseModel } from './interfaces';
import { IChangePasswordModel } from './interfaces';
import { IResetPasswordModel } from './interfaces';
import { IUserCreateDto } from './interfaces';
import { IUserIdModel } from './interfaces';
import { IUserAccountDto } from './interfaces';
import { IApiSettings } from './interfaces';
import { IBaseCampaignDto } from './interfaces';
import { IPackageDto } from './interfaces';
import { ICampaignGoalDto } from './interfaces';
import { IAddOnDto } from './interfaces';
import { IKeyValuesPairOASDto } from './interfaces';
import { ISeasonMappingDto } from './interfaces';
import { ISponsorDto } from './interfaces';
import { IThrivePackageEmailDetailDto } from './interfaces';
import { ICRMDto } from './interfaces';
import { ICampaignDto } from './interfaces';
import { IUploadPayloadModelDto } from './interfaces';
import { IFileDto } from './interfaces';
import { ISelectItem } from './interfaces';
import { ICampaignReportDto } from './interfaces';
import { ICampaignSettingsDto } from './interfaces';
import { ICampaignTransferDto } from './interfaces';
import { IEmailCreateDto } from './interfaces';
import { IEmailDto } from './interfaces';
import { IInstructionPageDto } from './interfaces';
import { IPayCampaignByCardModel } from './interfaces';
import { IPayCampaignByBankAccountModel } from './interfaces';
import { IGeographicDialogDataDto } from './interfaces';
import { ITeamDto } from './interfaces';
import { IUserTeamDto } from './interfaces';

@Injectable() export class AccountController
{
	public SignIn(model: ILoginModel) : Observable<any>
	{
		const body = <any>model;
		return this.httpClient.post<any>(
		this.settingsService.createApiUrl('Account/SignIn'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	public RefreshToken(refreshTokenModel: IRefreshTokenModel) : Observable<any>
	{
		const body = <any>refreshTokenModel;
		return this.httpClient.post<any>(
		this.settingsService.createApiUrl('Account/RefreshToken'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	public GetTimeZones() : Observable<ITimeZoneDto[]>
	{
		return this.httpClient.get<ITimeZoneDto[]>(
		this.settingsService.createApiUrl('Account/GetTimeZones'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetUsers(queryOptions: any) : Observable<IPagingResult>
	{
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Account/GetUsers') + queryOptions,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetUser(userId: any) : Observable<IUserDto>
	{
		const body = <any>{ 'userId': userId };
		return this.httpClient.get<IUserDto>(
		this.settingsService.createApiUrl('Account/GetUser'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: false,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetAllUsers() : Observable<IUserDto[]>
	{
		return this.httpClient.get<IUserDto[]>(
		this.settingsService.createApiUrl('Account/GetAllUsers'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetCurrentUserStatistics() : Observable<IUserStatisticsDto>
	{
		return this.httpClient.get<IUserStatisticsDto>(
		this.settingsService.createApiUrl('Account/GetCurrentUserStatistics'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetCurrentUserProperties(accountId: any) : Observable<IUserPropertiesDto>
	{
		const body = <any>{ 'accountId': accountId };
		return this.httpClient.get<IUserPropertiesDto>(
		this.settingsService.createApiUrl('Account/GetCurrentUserProperties'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetAllAccounts(queryOptions: any) : Observable<IPagingResult>
	{
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Account/GetAllAccounts') + queryOptions,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetAccountDetails(accountId: any) : Observable<IAccountDto>
	{
		const body = <any>{ 'accountId': accountId };
		return this.httpClient.get<IAccountDto>(
		this.settingsService.createApiUrl('Account/GetAccountDetails'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetAccountsByCurrentUser() : Observable<IAccountDto[]>
	{
		return this.httpClient.get<IAccountDto[]>(
		this.settingsService.createApiUrl('Account/GetAccountsByCurrentUser'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetUsersByAccount(accountId: any) : Observable<IUserDto[]>
	{
		const body = <any>{ 'accountId': accountId };
		return this.httpClient.get<IUserDto[]>(
		this.settingsService.createApiUrl('Account/GetUsersByAccount'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public ChangePassword(model: IChangePasswordModel) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('Account/ChangePassword'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public ResetPassword(model: IResetPasswordModel) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('Account/ResetPassword'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	public CreateUser(model: IUserCreateDto) : Observable<IUserPropertiesDto>
	{
		const body = <any>model;
		return this.httpClient.post<IUserPropertiesDto>(
		this.settingsService.createApiUrl('Account/CreateUser'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetAccountReportingLinks(queryOptions: any) : Observable<IPagingResult>
	{
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Account/GetAccountReportingLinks') + queryOptions,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public ChangeActiveStatus(model: IUserIdModel) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('Account/ChangeActiveStatus'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public CreateAccount(model: IAccountDto) : Observable<IAccountDto>
	{
		const body = <any>model;
		return this.httpClient.post<IAccountDto>(
		this.settingsService.createApiUrl('Account/CreateAccount'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public CreateAccountUser(model: IUserAccountDto) : Observable<IUserAccountDto>
	{
		const body = <any>model;
		return this.httpClient.post<IUserAccountDto>(
		this.settingsService.createApiUrl('Account/CreateAccountUser'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public SendRecoveryEmail(user: IUserDto) : Observable<string>
	{
		const body = <any>user;
		return this.httpClient.post<string>(
		this.settingsService.createApiUrl('Account/SendRecoveryEmail'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class ApplicationController
{
	public GetSettings() : Observable<IApiSettings>
	{
		return this.httpClient.get<IApiSettings>(
		this.settingsService.createApiUrl('Application/GetSettings'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class CampaignController
{
	public GetAllCampaigns(queryOptions: any) : Observable<IPagingResult>
	{
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Campaign/GetAllCampaigns') + queryOptions,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetCampaignsByUser(userId: any) : Observable<IBaseCampaignDto[]>
	{
		const body = <any>{ 'userId': userId };
		return this.httpClient.get<IBaseCampaignDto[]>(
		this.settingsService.createApiUrl('Campaign/GetCampaignsByUser'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetCampaignDetails(campaignId: any) : Observable<IBaseCampaignDto[]>
	{
		const body = <any>{ 'campaignId': campaignId };
		return this.httpClient.get<IBaseCampaignDto[]>(
		this.settingsService.createApiUrl('Campaign/GetCampaignDetails'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetPackagesDetails() : Observable<IPackageDto[]>
	{
		return this.httpClient.get<IPackageDto[]>(
		this.settingsService.createApiUrl('Campaign/GetPackagesDetails'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetCampaignGoals() : Observable<ICampaignGoalDto[]>
	{
		return this.httpClient.get<ICampaignGoalDto[]>(
		this.settingsService.createApiUrl('Campaign/GetCampaignGoals'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetCampaignAddOns() : Observable<IAddOnDto[]>
	{
		return this.httpClient.get<IAddOnDto[]>(
		this.settingsService.createApiUrl('Campaign/GetCampaignAddOns'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetKeyValuesPairsOAS() : Observable<IKeyValuesPairOASDto[]>
	{
		return this.httpClient.get<IKeyValuesPairOASDto[]>(
		this.settingsService.createApiUrl('Campaign/GetKeyValuesPairsOAS'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetAllSeasonMapping() : Observable<ISeasonMappingDto[]>
	{
		return this.httpClient.get<ISeasonMappingDto[]>(
		this.settingsService.createApiUrl('Campaign/GetAllSeasonMapping'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetAddOnsByCampaign(campaignId: any) : Observable<IAddOnDto[]>
	{
		const body = <any>{ 'campaignId': campaignId };
		return this.httpClient.get<IAddOnDto[]>(
		this.settingsService.createApiUrl('Campaign/GetAddOnsByCampaign'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetSponsors(accountId: any) : Observable<ISponsorDto[]>
	{
		const body = <any>{ 'accountId': accountId };
		return this.httpClient.get<ISponsorDto[]>(
		this.settingsService.createApiUrl('Campaign/GetSponsors'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public SaveCampaign(dto: IBaseCampaignDto) : Observable<IBaseCampaignDto>
	{
		const body = <any>dto;
		return this.httpClient.post<IBaseCampaignDto>(
		this.settingsService.createApiUrl('Campaign/SaveCampaign'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public SendEmailThrivePackage(dto: IThrivePackageEmailDetailDto) : Observable<IThrivePackageEmailDetailDto>
	{
		const body = <any>dto;
		return this.httpClient.post<IThrivePackageEmailDetailDto>(
		this.settingsService.createApiUrl('Campaign/SendEmailThrivePackage'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public SaveCampaignHS(dto: IBaseCampaignDto) : Observable<IBaseCampaignDto>
	{
		const body = <any>dto;
		return this.httpClient.post<IBaseCampaignDto>(
		this.settingsService.createApiUrl('Campaign/SaveCampaignHS'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public CreateCRM(cRMDto: ICRMDto) : Observable<ICRMDto>
	{
		const body = <any>cRMDto;
		return this.httpClient.post<ICRMDto>(
		this.settingsService.createApiUrl('Campaign/CreateCRM'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public SaveCreativeAssets(dto: IUploadPayloadModelDto) : Observable<ICampaignDto>
	{
		const body = <any>dto;
		return this.httpClient.post<ICampaignDto>(
		this.settingsService.createApiUrl('Campaign/SaveCreativeAssets'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetFilesFromAzure(dto: IBaseCampaignDto) : Observable<IBaseCampaignDto>
	{
		const body = <any>dto;
		return this.httpClient.post<IBaseCampaignDto>(
		this.settingsService.createApiUrl('Campaign/GetFilesFromAzure'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public DownloadFiles(fileName: string) : Observable<any>
	{
		const body = <any>fileName;
		return this.httpClient.post<any>(
		this.settingsService.createApiUrl('Campaign/DownloadFiles'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetFiles() : Observable<IFileDto>
	{
		return this.httpClient.get<IFileDto>(
		this.settingsService.createApiUrl('Campaign/GetFiles'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetCRM(queryOptions: any) : Observable<IPagingResult>
	{
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Campaign/GetCRM') + queryOptions,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetAssetsZip(campaignId: any) : Observable<number[]>
	{
		const body = <any>{ 'campaignId': campaignId };
		return this.httpClient.get<number[]>(
		this.settingsService.createApiUrl('Campaign/GetAssetsZip'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetCRMFiles(campaignId: any, specificFile: string) : Observable<number[]>
	{
		const body = <any>{ 'campaignId': campaignId,'specificFile': specificFile };
		return this.httpClient.get<number[]>(
		this.settingsService.createApiUrl('Campaign/GetCRMFiles'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetBillingStates() : Observable<ISelectItem[]>
	{
		return this.httpClient.get<ISelectItem[]>(
		this.settingsService.createApiUrl('Campaign/GetBillingStates'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GenerateCampaignInvoiceNumber(baseCampaign: IBaseCampaignDto) : Observable<IBaseCampaignDto>
	{
		const body = <any>baseCampaign;
		return this.httpClient.post<IBaseCampaignDto>(
		this.settingsService.createApiUrl('Campaign/GenerateCampaignInvoiceNumber'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetCampaignFile(fileName: string, campaignId: any) : Observable<number[]>
	{
		const body = <any>{ 'fileName': fileName,'campaignId': campaignId };
		return this.httpClient.get<number[]>(
		this.settingsService.createApiUrl('Campaign/GetCampaignFile'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetBillingCities(stateId: number) : Observable<ISelectItem[]>
	{
		const body = <any>{ 'stateId': stateId };
		return this.httpClient.get<ISelectItem[]>(
		this.settingsService.createApiUrl('Campaign/GetBillingCities'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetPSSByDealNumberQuery(campaignId: any, dealName: string) : Observable<any[]>
	{
		const body = <any>{ 'campaignId': campaignId,'dealName': dealName };
		return this.httpClient.get<any[]>(
		this.settingsService.createApiUrl('Campaign/GetPSSByDealNumberQuery'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public DeleteCampaignReport(dto: IBaseCampaignDto) : Observable<IBaseCampaignDto>
	{
		const body = <any>dto;
		return this.httpClient.post<IBaseCampaignDto>(
		this.settingsService.createApiUrl('Campaign/DeleteCampaignReport'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public UploadReport(payload: IUploadPayloadModelDto) : Observable<number[]>
	{
		const body = <any>payload;
		return this.httpClient.post<number[]>(
		this.settingsService.createApiUrl('Campaign/UploadReport'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetCampaignReport(dto: ICampaignReportDto) : Observable<IPagingResult>
	{
		const body = <any>dto;
		return this.httpClient.post<IPagingResult>(
		this.settingsService.createApiUrl('Campaign/GetCampaignReport'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public SetReportVisibility(campaignId: any, isReportVisible: boolean) : Observable<number[]>
	{
		const body = <any>{ 'campaignId': campaignId,'isReportVisible': isReportVisible };
		return this.httpClient.get<number[]>(
		this.settingsService.createApiUrl('Campaign/SetReportVisibility'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetCampaignExternalIds(campaignId: any) : Observable<any>
	{
		const body = <any>{ 'campaignId': campaignId };
		return this.httpClient.get<any>(
		this.settingsService.createApiUrl('Campaign/GetCampaignExternalIds'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public SetCampaignExternalIds(dto: any) : Observable<any>
	{
		const body = <any>dto;
		return this.httpClient.post<any>(
		this.settingsService.createApiUrl('Campaign/SetCampaignExternalIds'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public BulkChangeCampaignSettings(dto: ICampaignSettingsDto) : Observable<ICampaignSettingsDto>
	{
		const body = <any>dto;
		return this.httpClient.post<ICampaignSettingsDto>(
		this.settingsService.createApiUrl('Campaign/BulkChangeCampaignSettings'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetCampaignGroupedBySponsor(accountId: any, fiscalYear: string, searchTerm: string, orderField: string, order: number, type: string, skip: number = 0, take: number = 10) : Observable<IPagingResult>
	{
		const body = <any>{ 'accountId': accountId,'fiscalYear': fiscalYear,'searchTerm': searchTerm,'orderField': orderField,'order': order,'type': type,'skip': skip,'take': take };
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Campaign/GetCampaignGroupedBySponsor'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetTakeOutCalendarData() : Observable<IPagingResult>
	{
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Campaign/GetTakeOutCalendarData'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetTransferDataCampaigns(accountId: any, campaignId: any, isLearfieldCampaign: boolean, isOASCampaign: boolean, seasonId: any, searchParam: string, skip: number = 0, take: number = 10) : Observable<IPagingResult>
	{
		const body = <any>{ 'accountId': accountId,'campaignId': campaignId,'isLearfieldCampaign': isLearfieldCampaign,'isOASCampaign': isOASCampaign,'seasonId': seasonId,'searchParam': searchParam,'skip': skip,'take': take };
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Campaign/GetTransferDataCampaigns'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetPSSBraodcastContractLineByCampaignIdQuery(campaignId: any) : Observable<IPagingResult>
	{
		const body = <any>{ 'campaignId': campaignId };
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Campaign/GetPSSBraodcastContractLineByCampaignIdQuery'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public TransferCampaigns(dto: ICampaignTransferDto) : Observable<ICampaignTransferDto>
	{
		const body = <any>dto;
		return this.httpClient.post<ICampaignTransferDto>(
		this.settingsService.createApiUrl('Campaign/TransferCampaigns'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetTransferedCampaign(campaignId: any) : Observable<IPagingResult>
	{
		const body = <any>{ 'campaignId': campaignId };
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Campaign/GetTransferedCampaign'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public SendPremiumPageTakeOverEmail(campaignId: any) : Observable<IPagingResult>
	{
		const body = <any>{ 'campaignId': campaignId };
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Campaign/SendPremiumPageTakeOverEmail'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class EmailController
{
	public CreateEmail(emailMessage: IEmailCreateDto) : Observable<ISampleResponseModel>
	{
		const body = <any>emailMessage;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('Email/CreateEmail'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public EmailServiceTrigger() : Observable<ISampleResponseModel>
	{
		return this.httpClient.get<ISampleResponseModel>(
		this.settingsService.createApiUrl('Email/EmailServiceTrigger'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetEmails() : Observable<IEmailDto[]>
	{
		return this.httpClient.get<IEmailDto[]>(
		this.settingsService.createApiUrl('Email/GetEmails'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class ErrorController
{
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class InstructionsController
{
	public GetAllInstructionPages() : Observable<IInstructionPageDto[]>
	{
		return this.httpClient.get<IInstructionPageDto[]>(
		this.settingsService.createApiUrl('Instructions/GetAllInstructionPages'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	public GetInstructionPageContent(pageId: any) : Observable<number[]>
	{
		const body = <any>{ 'pageId': pageId };
		return this.httpClient.get<number[]>(
		this.settingsService.createApiUrl('Instructions/GetInstructionPageContent'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: false,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public UpdateInstructionPage(payload: IUploadPayloadModelDto) : Observable<number[]>
	{
		const body = <any>payload;
		return this.httpClient.post<number[]>(
		this.settingsService.createApiUrl('Instructions/UpdateInstructionPage'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public RemoveInstructionPage(dto: IInstructionPageDto) : Observable<IInstructionPageDto>
	{
		const body = <any>dto;
		return this.httpClient.post<IInstructionPageDto>(
		this.settingsService.createApiUrl('Instructions/RemoveInstructionPage'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class PaymentController
{
	public PayCampaignByCard(model: IPayCampaignByCardModel) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('Payment/PayCampaignByCard'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public PayCampaignByBankAccount(model: IPayCampaignByBankAccountModel) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('Payment/PayCampaignByBankAccount'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class TargetingController
{
	public GetGeographicDialogData(campaignId: any, loadZipCodes: boolean, stateAbbreviation: string = "") : Observable<IGeographicDialogDataDto>
	{
		const body = <any>{ 'campaignId': campaignId,'loadZipCodes': loadZipCodes,'stateAbbreviation': stateAbbreviation };
		return this.httpClient.get<IGeographicDialogDataDto>(
		this.settingsService.createApiUrl('Targeting/GetGeographicDialogData'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class TeamController
{
	public CreateTeam(model: ITeamDto) : Observable<ITeamDto>
	{
		const body = <any>model;
		return this.httpClient.post<ITeamDto>(
		this.settingsService.createApiUrl('Team/CreateTeam'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetTeams(queryOptions: any) : Observable<IPagingResult>
	{
		return this.httpClient.get<IPagingResult>(
		this.settingsService.createApiUrl('Team/GetTeams') + queryOptions,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public CreateUserTeam(model: IUserTeamDto) : Observable<IUserTeamDto>
	{
		const body = <any>model;
		return this.httpClient.post<IUserTeamDto>(
		this.settingsService.createApiUrl('Team/CreateUserTeam'),
		body,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class TestController
{
	public GetPrivateData() : Observable<string>
	{
		return this.httpClient.get<string>(
		this.settingsService.createApiUrl('Test/GetPrivateData'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
