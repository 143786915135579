    <card-item header="Company" *ngIf="baseCampaign.campaignType != enumAccountType.FAN365 && baseCampaign.campaignType != enumAccountType.Social && baseCampaign.campaignType != enumAccountType.Content && baseCampaign.campaignType != enumAccountType.OAS" [content]="baseCampaign.accountName" [cardType]="'generic'"></card-item>
    <card-item header="Property" *ngIf="baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS || baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content " [content]="baseCampaign.accountName"></card-item>
    <card-item header="Contact" #contactCard [contactInfo]="contactInfo" [baseCampaign]="baseCampaign" [cardType]="'contact'" [content]="contactValues" [editButton]="baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS || baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content" (onEdit)="editEvent($event)"></card-item>
    <card-item header="Sponsor" [cardType]="'generic'" *ngIf="baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS || baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content" [content]="baseCampaign.sponsor"></card-item>

    <card-item header="Campaign Name" [cardType]="'generic'" [content]="baseCampaign.name"></card-item>
    <card-item header="Campaign Goal" *ngIf="baseCampaign.account.isCampaignCreation" [cardType]="'generic'" [content]="campaignGoalLabel"></card-item>
    <card-item header="Campaign Plan" *ngIf="baseCampaign.account.isCampaignCreation" [cardType]="'generic'" [content]="baseCampaign.package.name"></card-item>
    <card-item header="Campaign Dates" *ngIf="isVisible" [cardType]="'generic'" 
        [editButton]="(canEditCampaignDates && !editButtonShown && baseCampaign.isDataRangeUnlocked) || 
                        ((baseCampaign.campaignType == enumAccountType.OAS || baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content) 
                            && ((baseCampaign.campaignStatusId == 1 && currentUser.permissions.isClient) 
                                || ((currentUser.permissions.isAdmin || currentUser.permissions.isEmployee || currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin 
                                        ||  currentUser.userRole == enumUserRole.LIMGCSocialAdOps)
                                    ) ) )
                        || (baseCampaign.campaignType == enumAccountType.FAN365 && (currentUser.permissions.isAdmin || currentUser.permissions.isEmployee 
                                    || currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin ||  currentUser.userRole == enumUserRole.LIMGCSocialAdOps) 
                           )" [content]="campaignDates" (onEdit)="editEvent($event)" [isNoteValid]="isNoteValid"></card-item>
    <!-- Removed from line 14/15 -->
    <!-- && (baseCampaign.campaignStatusId == enumCampaignStatus.Assets_Pending || baseCampaign.campaignStatusId == enumCampaignStatus.Assets_Uploaded || baseCampaign.campaignStatusId == enumCampaignStatus.Assets_Approved || baseCampaign.campaignStatusId == enumCampaignStatus.Assets_Declined || baseCampaign.campaignStatusId == enumCampaignStatus.Campaign_Ended || baseCampaign.statusId == enumCampaignStatus.Campaign_Ended) -->
    <!-- Removed from line 17/18 -->
    <!-- && (baseCampaign.campaignStatusId == enumCampaignStatus.Assets_Pending || baseCampaign.campaignStatusId == enumCampaignStatus.Assets_Uploaded || baseCampaign.campaignStatusId == enumCampaignStatus.Assets_Approved || baseCampaign.campaignStatusId == enumCampaignStatus.Assets_Declined ) -->
    
    <card-item *ngIf="baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS || baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content" header="Deal Number" [cardType]="'generic'" [content]="baseCampaign.dealName"></card-item>

    <!-- Don't remove impression card, only remove editalbe option -->

    <card-item *ngIf="baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS || (isVisible && baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content)" header="Impressions" [content]="impressions" [editButton]="(baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content) && baseCampaign.campaignStatusId == 1" [isShareOfVoice]="baseCampaign.campaignType == enumAccountType.OAS ? baseCampaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == enumInventoryTypeOAS.Share_Of_Voice : false" [cardType]="'inventoryAddOn'" (onEdit)="editEvent($event)"></card-item>

    <card-item *ngIf="baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS || baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content" header="Inventory items" [cardType]="'generic'" [content]="inventoryItems"></card-item>
    <!-- <card-item header="Channels selected" [content]="baseCampaign.channelDtos" [editButton]="!editButtonShown"></card-item> -->
    <card-item header="Creative selected" [cardType]="'generic'" [content]="creativesSelected"></card-item>
    
    <card-item *ngIf="!currentUser.permissions.isClient && (baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS)" [editButton]="currentUser.permissions.canVerifyCampaign && !editButtonShown" [header]="(baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS || baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content) ? 'Advertiser ID' : 'Advertiser'" [cardType]="'advertiser'" [content]="(baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS || baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content) ? [baseCampaign.advertiserId, baseCampaign.ttdAdvertiserId, baseCampaign.dV360AdvertiserId, baseCampaign.gamAdvertiserId, baseCampaign.campaignType] : [baseCampaign.advertiserId, baseCampaign.advertiserName]" (onEdit)="editEvent($event)"></card-item>
 
    <card-item *ngIf="isPageTakeOver && isVisible" [editButton]="canEditKeyValuePair && !editButtonShown" [cardType]="'keyValuePair'" header="Page Selection" [content]="baseCampaign.keyValuesPairOASPageTamplate || baseCampaign.keyValuesPairOASSection ? [baseCampaign.keyValuesPairOASSection.friendlyValues, baseCampaign.keyValuesPairOASPageTamplate.friendlyValues] : '-'" (onEdit)="editEvent($event)"></card-item>

    <div *ngIf="baseCampaign.account!=null && baseCampaign.account.isCampaignCreation && !currentUser.permissions.isClient && (baseCampaign.campaignStatusId < 7 || baseCampaign.statusId < 7 || baseCampaign.campaignStatusId == 12 || baseCampaign.campaignStatusId == 14 )">
        <card-item header="Payment Status" [content]="baseCampaign.campaignStatusId" [editButton]="!editButtonShown" [cardType]="'paymentStatus'" (onEdit)="editEvent($event)"></card-item>
    </div>

    <span *ngIf="checkIsFacebookInventoryItem()">
        <card-item [cardType]="'social'" [header]="!!baseCampaign.account && (baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS || baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content) ? 'Sponsor Facebook Page' : 'Facebook Page'" [content]="[baseCampaign.facebookPageName,baseCampaign.facebookPageId, baseCampaign.facebookPageUrl]" [editButton]="!editButtonShown" [cardType]="'generic'" (onEdit)="editEvent($event)"></card-item>

        <card-item [cardType]="'social'" [header]="!!baseCampaign.account && (baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS || baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content) ? 'Sponsor Instagram Page' : 'Instagram Page'" [content]="[baseCampaign.instagramName,baseCampaign.instagramId]" [editButton]="!editButtonShown" [cardType]="'generic'" (onEdit)="editEvent($event)"></card-item>
    </span>
    <div *ngIf="baseCampaign.account!=null && (baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS)">
        <card-item header="Landing Page" [content]="baseCampaign.landingPageJson.url" [editButton]="landingPageEditButtonValidation()" [cardType]="'landingPage'" (onEdit)="editEvent($event)"></card-item>
    </div>


    <div *ngIf="baseCampaign.account!=null && (baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS) && canEditDemographics && isVisible">
        <card-item header="Demographics" [content]="demographicsValues" [editButton]="canEditDemographics && !editButtonShown" [cardType]="'demographics'" (onEdit)="editEvent($event)"></card-item>
    </div>

    <div *ngIf="baseCampaign.account!=null && (baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS) && canEditGeographics && baseCampaign.campaignStatusId == 1 && isVisible">
        <card-item header="Geographic" [baseCampaign]="baseCampaign" [content]="geographicsValues" [editButton]="canEditGeographics && !editButtonShown" [cardType]="'geographics'" (onEdit)="editEvent($event)"></card-item>
    </div>



    <div *ngIf="baseCampaign.account!=null && baseCampaign.campaignType == enumAccountType.FAN365 && (canEditSiteVisitTracking || canEditConversionTracking || canEditInterestTargeting) && isVisible">
        <card-item header="Additional Settings" [content]="additionalSettingsList" [editButton]="!editButtonShown" [cardType]="'additionalSettings'" (onEdit)="editEvent($event)"></card-item>
    </div>
    <div *ngIf="(baseCampaign.account!=null && baseCampaign.campaignType == enumAccountType.FAN365 && (userType.permissions.isEmployee || userType.permissions.isAdmin))">
        <card-item header="Unlock Features" [content]="unlockFeaturesList" [editButton]="!editButtonShown" [cardType]="'unlockFeatures'" (onEdit)="editEvent($event)"></card-item>
    </div>
    <div *ngIf="baseCampaign.account!=null && ((baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS || baseCampaign.campaignType == enumAccountType.Social || baseCampaign.campaignType == enumAccountType.Content) && (currentUser.userRole == enumUserRole.Media_Activation || currentUser.userRole == enumUserRole.Admin || currentUser.userRole == enumUserRole.LIMGC_AdOps_Admin || currentUser.userRole == enumUserRole.LIMGCSocialAdOps || currentUser.userRole == enumUserRole.Client_Solution))  ">
        <card-item header="Activation" [content]="activation" [editButton]="true" [cardType]="'additionalSettings'" (onEdit)="editEvent($event)"></card-item>
    </div>

    <div class="add-ons-cost-card" style="display: flex;flex-direction: column;background-color: white;" *ngIf="(userType.permissions.isHMPClient || baseCampaign.campaignType == enumAccountType.HMP) && costsTableValidation">
        <div style="display: flex;flex-direction: column;">
            <div class="add-ons-cost-card-content" style="display: flex;flex-direction: row;justify-content: space-between; font-weight: bold; padding-bottom: 25px;">
                <div>
                    Total MPC Cost
                </div>
                <div>
                    ${{ (baseCampaign.amount - (baseCampaign.totalFacebookAddOnAmount  + baseCampaign.totalDisplayAddOnAmount + baseCampaign.totalCreativeFlightsAddOnAmount + baseCampaign.totalOtherAddOnAmount)) | number:'1.2-2' }}

                </div>
            </div>
            <div class="add-ons-cost-card-header">ADD-ONS</div>
            <div class="add-ons-cost-card-content" style="display: flex;flex-direction: row;justify-content: space-between;">
                <div>Facebook</div>
                <div>${{ baseCampaign.totalFacebookAddOnAmount | number:'1.0-2' }}</div>
            </div>
            <div class="add-ons-cost-card-content" style="display: flex;flex-direction: row;justify-content: space-between;">
                <div>
                    Display
                </div>
                <div>
                    ${{ baseCampaign.totalDisplayAddOnAmount | number:'1.0-2'}}
                </div>
            </div>
            <div class="add-ons-cost-card-content" style="display: flex;flex-direction: row;justify-content: space-between;">
                <div>
                    Other
                </div>
                <div>
                    ${{ baseCampaign.totalOtherAddOnAmount | number:'1.0-2'}}
                </div>
            </div>
            <div class="add-ons-cost-card-content" style="border-bottom: 1px solid rgb(214, 210, 210);padding-bottom: 15px; display: flex;flex-direction: row;justify-content: space-between;">
                <div>
                    Creative flights
                </div>
                <div>
                    ${{ baseCampaign.totalCreativeFlightsAddOnAmount | number:'1.0-2'}}
                </div>
            </div>
            <div class="add-ons-cost-card-content" style="display: flex;flex-direction: row;justify-content: space-between; font-weight: bold;">
                <div>
                    Total Add-On Cost
                </div>
                <div>
                    ${{ baseCampaign.totalFacebookAddOnAmount  +  baseCampaign.totalDisplayAddOnAmount + baseCampaign.totalOtherAddOnAmount + baseCampaign.totalCreativeFlightsAddOnAmount | number:'1.0-2' }}

                </div>
            </div>
        </div>
        <div class="add-ons-cost-card-content" style="border-bottom: 1px solid rgb(214, 210, 210);display: flex;flex-direction: row;justify-content: space-between;font-weight: bold;padding-bottom: 25px;">
            <div>Total Build Fee</div>
            <div> ${{ baseCampaign.buildFee | number:'1.0-2'}}</div>
        </div>

        <div class="add-ons-cost-card-content" style="display: flex;flex-direction: row;justify-content: space-between;font-weight: bold;">
            <div>TOTAL COST:</div>
            <div>${{ baseCampaign.totalAmount  | number:'1.0-2'}}</div>

        </div>

        <div class="add-ons-cost-card-content" style="display: flex;flex-direction: row;justify-content: space-between;font-weight: bold;">
            <div>MINIMUM IMPRESSIONS:</div>
            <div>{{ (baseCampaign.amount / minCpm * 1000) - (baseCampaign.amount / minCpm * 1000)%1 | number:'1.0-0'}}</div>
        </div>

        <div class="add-ons-cost-card-content" style="display: flex;flex-direction: row;justify-content: space-between;font-weight: bold; margin-top: -3px;">
            <div>MAXIMUM IMPRESSIONS:</div>
            <div>{{ (baseCampaign.amount / maxCpm * 1000) - (baseCampaign.amount / maxCpm * 1000)%1 | number:'1.0-0'}}</div>
        </div>
        
        <div class="add-ons-cost-card-header" style="margin-top: -3px;">*Impression amounts are subject to change based on time of year and add-ons selected.</div>
    
    </div>

    <div class="add-ons-cost-card" style="display: flex;flex-direction: column;background-color: white;" *ngIf="(userType.permissions.isHMPClient || baseCampaign.campaignType == enumAccountType.HMP) && !costsTableValidation">
        <div style="display: flex;flex-direction: column;">
            <div class="invalid-message-box d-flex align-items-center" style="min-height: 3em;">
                <span class="px-2 py-1">Something went wrong with costs calculation. Please check did you select everything properly.</span>
            </div>
        </div> 
    </div>
    




    <demographic-dialog #demographics (onSave)="demographicsSaved($event)"></demographic-dialog>
    <geographic-dialog #geographics (onSave)="geographicSaved($event)"></geographic-dialog>
    <additional-settings-dialog #additionalSettings (onSave)="additionalSettingsSaved($event)"></additional-settings-dialog>
    <unlock-features-dialog #unlockFeatures (onSave)="unlockFeaturesSaved($event)"></unlock-features-dialog>
    <advertiser-dialog #advertiser (onSave)="dataSaved($event)"></advertiser-dialog>
    <payment-status-dialog #paymentStatus (onSave)="dataSaved($event)"></payment-status-dialog>
    <campaign-dates-dialog #campaignDatesDialog (onSave)="campaignDatesSaved($event)"></campaign-dates-dialog>
    <key-value-pair-dialog #keyValuePair (onSave)="dataSaved($event)"></key-value-pair-dialog>
    <inventory-add-on-dialog #inventoryAddOn (onSave)="pssAdOnSaved($event)"></inventory-add-on-dialog>
    <contact-dialog #contact  (onSave)="dataSaved($event)"></contact-dialog>


    <p-dialog [(visible)]="display" modal="true">
        <p-header>
            Edit {{ modalContext }} Details
        </p-header>
        <div class="mb-2 mt-2 row align-items-center">
            <div class="col-md-5 font-weight-bold">
                {{ modalContext }} Page Name
            </div>
            <div class="col-md-7 mb-2">
                <input type="text" [(ngModel)]="name" pInputText />
            </div>

            <div class="col-md-5 font-weight-bold">
                {{ modalContext }} Page ID
            </div>
            <div class="col-md-7 mb-2">
                <input type="text" [(ngModel)]="id" pInputText />
            </div>

            <div class="col-md-5 font-weight-bold" *ngIf="modalContext == 'Facebook'">
                {{ modalContext }} Page URL
            </div>
            <div class="col-md-7" *ngIf="modalContext == 'Facebook'">
                <input type="text" [(ngModel)]="URL" pInputText />
            </div>
        </div>
        <p-footer>
            <div class="mb-3">
                <button (click)="cancel()" pButton class="px-3 flat-btn" label="Cancel"></button>
                <button (click)="save()" pButton class="px-3 p-button-primary" label="Save"></button>
            </div>
        </p-footer>

    </p-dialog>
