import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountController, CampaignController, EmailController, InstructionsController, PaymentController, TeamController, TestController } from '@Workspace/_generated/services';
import { PendingChangesGuard } from '@Workspace/guards';
import { NotificationService } from '@Workspace/services';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { SplitButtonModule } from 'primeng/splitbutton';
import { QuillModule } from 'ngx-quill';

import { HomeComponentComponent } from '../dashboard/components/home-component/home-component.component';
import { CampaignCardDetails } from '../shared-core/components/campaign-card-details/campaign-card-details.component';
import { SharedCoreModule } from '../shared-core/shared-core.module';
import { AccountDetailComponent } from './components/account/account-detail/account-detail.component';
import { AccountEditDialogComponent } from './components/account/account-edit/account-edit-dialog.component';
import { AccountListComponent } from './components/account/account-list/account-list.component';
import { AccountReportingComponent } from './components/account/account-reporting/account-reporting.component';
import { CampaignListGroupComponent } from './components/campaign-list-group/campaign-list-group.component';
import { CampaignListComponent } from './components/campaign-list/campaign-list.component';
import { CampaignApprovalComponent } from './components/campaign/campaign-approval/campaign-approval.component';
import { CampaignDetailCreateComponent } from './components/campaign/campaign-detail-create/campaign-detail.component';
import { CampaignPackagePreviewComponent } from './components/campaign/campaign-package-preview/campaign-package-preview.component';
import { CampaignPaymentComponent } from './components/campaign/campaign-payment/campaign-payment.component';
import { CampaignReportAssetsComponent } from './components/campaign/campaign-report/campaign-report-assets/campaign-report-assets.component';
import { CampaignReportDetailComponent } from './components/campaign/campaign-report/campaign-report-detail/campaign-report-detail.component';
import { CampaignReportManageComponent } from './components/campaign/campaign-report/campaign-report-manage/campaign-report-manage.component';
import { CampaignReviewComponent } from './components/campaign/campaign-review/campaign-review.component';
import { CapmaignSuccessCardComponent } from './components/campaign/campaign-success-card/campaign-success-card.component';
import { CampaignThrivePackageComponent } from './components/campaign/campaign-thrive-package/campaign-thrive-package.component';
import { DisplayPremiumComponent } from './components/campaign/creatives/display-premium/display-premium.component';
import { DisplayComponent } from './components/campaign/creatives/display/display.component';
import { FacebookCarouselComponent } from './components/campaign/creatives/facebook-carousel/facebook-carousel.component';
import { FacebookStaticVideoComponent } from './components/campaign/creatives/facebook-static-video/facebook-static-video.component';
import { FacebookStaticComponent } from './components/campaign/creatives/facebook-static/facebook-static.component';
import { GoogleAdWordsComponent } from './components/campaign/creatives/google-adWords/google-adWords.component';
import { InStreamVideoComponent } from './components/campaign/creatives/in-stream-video/inStreamVideo.component';
import { ConnectedTvComponent } from './components/campaign/creatives/connected-tv/connected-tv.component';
import { InstantExperienceComponent } from './components/campaign/creatives/instant-experience/instant-experience.component';
import { LeadGenerationComponent } from './components/campaign/creatives/lead-generation/lead-generation.component';
import { NativeImageComponent } from './components/campaign/creatives/native-image/native-image.component';
import { NativeVideoComponent } from './components/campaign/creatives/native-video/native-video.component';
import { SpotifyAudioComponent } from './components/campaign/creatives/spotify-audio/spotify-audio.component';
import { SpotifyVideoComponent } from './components/campaign/creatives/spotify-video/spotify-video.component';
import { StoriesComponent } from './components/campaign/creatives/stories/stories.component';
import { CRMListComponent } from './components/crm/crm-list/crm-list.component';
import { UploadCrmFormComponent } from './components/crm/upload-crm-modal/upload-crm.modal';
import { HelpDocumentsComponent } from './components/documents/help-documents.component';
import { HeaderComponent } from './components/header-component/header.component';
import { EditInstructionsDialog } from './components/instructions/edit-instructions-dialog/edit-instructions.dialog';
import { InstructionsComponent } from './components/instructions/instructions.component';
import { MediaPreviewDialog } from './components/media-preview/media-preview.dialog';
import { PageInternalHeader } from './components/page-internal-header/page-internal-header.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignupComponent } from './components/sign-up/sign-up.component';
import { SuCampaignChannelComponent } from './components/standard-users/su-campaign/su-campaign-channels/su-campaign-channels.component';
import { SuCampaignCreativeComponent } from './components/standard-users/su-campaign/su-campaign-creative/su-campaign-creatives.component';
import { SuCampaignDetailComponent } from './components/standard-users/su-campaign/su-campaign-detail-create/su-campaign-detail-create.component';
import { SuCampaignReviewComponent } from './components/standard-users/su-campaign/su-campaign-review/su-campaign-review.component';
import { StepsNavigationComponent } from './components/steps-navigation/steps-navigation.component';
import { TeamDetailComponent } from './components/team/team-detail/team-detail.component';
import { TeamEditDialogComponent } from './components/team/team-edit/team-edit-dialog.component';
import { TeamListComponent } from './components/team/team-list/team-list.component';
import { UserDetailComponent } from './components/user/user-detail/user-detail.component';
import { UserEditDialogComponent } from './components/user/user-edit/user-edit-dialog.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { UserPicklistComponent } from './components/user/user-picklist/user-picklist.component';
import { MogoRoutingModule } from './mogo-routing.module';
import { MogoComponent } from './mogo.component';
import { AccountPage } from './pages/account/account.page';
import { CampaignReviewPage } from './pages/campaign-review/campaign-review-page';
import { CampaignUploadPageFan365 } from './pages/campaign-uploads-fan365/campaign-upload-page-fan365';
import { CampaignUploadPage } from './pages/campaign-uploads/campaign-upload-page';
import { CampaignDetailPageComponent } from './pages/campaign/campaign-detail-page';
import { CampaignPackagePreviewPageComponent } from './pages/campaign/campaign-package-preview/campaign-package-preview-page';
import { CampaignPaymentPage } from './pages/campaign/campaign-payment/campaign-payment-page';
import { CampaignPreviewPage } from './pages/campaign/campaign-preview.page';
import { CampaignReportPage } from './pages/campaign/campaign-report/campaign-report.page';
import { CampaignSuccessCardPage } from './pages/campaign/campaign-success-card/campaign-success-card.page';
import { HelpDocumentsPage } from './pages/documents/help-documents.page';
import { EmployeePage } from './pages/employee/employee.page';
import { CampaignCalendar } from './components/campaign-calendar-page/campaign-calendar-page';
import { InstructionsPage } from './pages/instructions/instructions.page';
import { SignInPageComponent } from './pages/sign-in/sign-in.page';
import { SignupPageComponent } from './pages/sign-up/sign-up.page';
import { StepsNavigationPageComponent } from './pages/steps-navigation/steps-navigation.page';
import { UploadNewCrmPage } from './pages/upload-new-crm/upload-new-crm.page';
import { CampaignService } from './services/campaign.service';
import { FileUploadService } from './services/file-upload.service';
import { OASComponent } from './components/campaign/creatives/OAS/oas.component';
import { SocialGeneralComponent } from './components/campaign/creatives/social-general/social-general.component';
import { SocialLinkCampaign } from './components/campaign/creatives/social-link-campaign/social-link-campaign.component';
import { SocialContentCreation } from './components/campaign/creatives/social-content-creation/social-content-creation.component';
import { SocialCataboom } from './components/campaign/creatives/social-cataboom/social-cataboom.component';
import { CampaignReportTableComponent } from './components/campaign/campaign-report/campaign-report-table/campaign-report-table.component';
import { CampaignReportExternalIdsComponent } from './components/campaign/campaign-report/campaign-report-external-ids/campaign-report-external-ids.component';
import { CampaignBulkEditSettingsDialog } from './components/campaign-bulk-edit-settings-dialog/campaign-bulk-edit-settings-dialog';
import { CampaignTransferDataDialog } from './components/campaign-transfer-data-dialog/campaign-transfer-data-dialog';
import {ContextMenuModule} from 'primeng/contextmenu';
import { ContactDialog } from '../shared-core/components/contact-dialog/contact-dialog';
import {FileUploadModule} from 'primeng/fileupload';
import { ChangePasswordDialogComponent } from './components/user/change-password-dialog/change-password-dialog.component';
import { ChangePasswordFormComponent } from './components/user/change-password-form/change-password-form.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ServiceAgreementComponent } from './components/service-agreement/service-agreement.component';
import { ContentComponent } from './components/campaign/creatives/content/content.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
    declarations: [
        CampaignDetailCreateComponent,
        CampaignDetailPageComponent,
        CampaignPreviewPage,
        CampaignListComponent,
        CampaignListGroupComponent,
        CampaignReportDetailComponent,
        CampaignReportManageComponent,
        CampaignReportAssetsComponent,
        CampaignReportTableComponent,
        CampaignReportExternalIdsComponent,
        CampaignReportPage,
        CampaignThrivePackageComponent,
        HeaderComponent,
        UserDetailComponent,
        MogoComponent,
        UserListComponent,
        CampaignUploadPage,
        CampaignPackagePreviewPageComponent,
        CampaignPackagePreviewComponent,
        CampaignPaymentComponent,
        CampaignPaymentPage,
        CampaignSuccessCardPage,
        CapmaignSuccessCardComponent,
        AccountDetailComponent,
        AccountListComponent,
        AccountPage,
        SignInComponent,
        SignupComponent,
        SignInPageComponent,
        SignupPageComponent,
        UserEditDialogComponent,
        ChangePasswordDialogComponent,
        ChangePasswordFormComponent,
        AccountEditDialogComponent,
        StepsNavigationComponent,
        StepsNavigationPageComponent,
        CampaignApprovalComponent,
        // Standard Users
        SuCampaignDetailComponent,
        SuCampaignChannelComponent,
        SuCampaignCreativeComponent,
        SuCampaignReviewComponent,
        CampaignCardDetails,
        CRMListComponent,
        MediaPreviewDialog,
        EmployeePage,
        CampaignCalendar,
        UploadNewCrmPage,
        TeamDetailComponent,
        TeamEditDialogComponent,
        TeamListComponent,
        UserPicklistComponent,
        UploadNewCrmPage,
        UploadCrmFormComponent,
        UserPicklistComponent,
        HeaderComponent,
        HomeComponentComponent,
        UserPicklistComponent,
        CampaignReviewComponent,
        CampaignReviewPage,
        InstructionsComponent,
        InstructionsPage,
        EditInstructionsDialog,
        PrivacyPolicyComponent,
        ServiceAgreementComponent,
        HelpDocumentsComponent,
        HelpDocumentsPage,
        CampaignUploadPageFan365,
        PageInternalHeader,
        AccountReportingComponent,
        InStreamVideoComponent,
        ConnectedTvComponent,
        NativeImageComponent,
        NativeVideoComponent,
        SpotifyVideoComponent,
        SpotifyAudioComponent,
        FacebookCarouselComponent,
        FacebookStaticComponent,
        DisplayComponent,
        DisplayPremiumComponent,
        FacebookStaticVideoComponent,
        GoogleAdWordsComponent,
        StoriesComponent,
        LeadGenerationComponent,
        InstantExperienceComponent,
        OASComponent,
        SocialGeneralComponent,
        SocialLinkCampaign,
        SocialContentCreation,
        SocialCataboom,
        ContentComponent,
        CampaignBulkEditSettingsDialog,
        CampaignTransferDataDialog,
        ContactDialog
        // Directives
    ],
    imports: [
        MogoRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MessageModule,
        MessagesModule,
        // TableModule,
        SplitButtonModule,
        ConfirmDialogModule,
        SharedCoreModule,
        ContextMenuModule,
        FileUploadModule,
        FontAwesomeModule,
        QuillModule.forRoot()
    ],
    providers: [
        AccountController,
        TeamController,
        TestController,
        EmailController,
        CampaignController,
        PaymentController,
        InstructionsController,
        NotificationService,
        CampaignService,
        FileUploadService,
        PendingChangesGuard
    ],
    exports: [SignInPageComponent]
})
export class MogoModule { }
