<div>
    <div class="ml-3">
        <div [formGroup]="formGroup">

            <div class="row mb-3">
                <div class="col-12">
                    <div class="display-4">
                        Add New Account Information
                    </div>

                    <div class="sub-heading">
                        Create a brand new account
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="row mb-3">
                        <div class="col-4">
                            <label class="required">
                                Company Name
                            </label>
                        </div>

                        <div class="col-8">
                            <input pInputText (change)="onCompanyNameChange()" maxlength="50" formControlName="companyName" class="w-100" />
                        </div>
                        <div class="offset-4 col-8" style="color: red;" *ngIf="equalCompaniesName">
                            The Company Name is already taken, please enter the new one.
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <label class="required">
                                Type
                            </label>
                        </div>

                        <div class="col-8">
                            <p-dropdown autoWidth="false" [style]="{'width':'100%'}" formControlName="companyType" (onChange)="selectedCompanyType($event)" [options]="companyTypes"></p-dropdown>
                        </div>

                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <label>
                                Industry
                            </label>
                        </div>

                        <div class="col-8">
                            <p-dropdown autoWidth="false" [style]="{'width':'100%'}" formControlName="industry" (onChange)="selectedCompanyType($event)" [options]="industries"></p-dropdown>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <label>
                                Notification Team
                            </label>
                        </div>

                        <div class="col-8">
                            <p-dropdown autoWidth="false" [style]="{'width':'100%'}" formControlName="notificationGroup" (onChange)="selectedCompanyType($event)" [options]="notificationGroups"></p-dropdown>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <label>
                                Automatic API build
                            </label>
                        </div>

                        <div class="col-8">
                            <p-inputSwitch formControlName="apiBuild"></p-inputSwitch>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="row mb-3">
                        <div class="col-4">
                            <label>
                                GCM Advertiser ID
                            </label>
                        </div>

                        <div class="col-8">
                            <input pInputText maxlength="50" formControlName="GCMAdvertiserId" class="w-100" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <label>
                                TTD Advertiser ID
                            </label>
                        </div>

                        <div class="col-8">
                            <input pInputText maxlength="50" formControlName="TTDAdvertiserId" class="w-100" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <label>
                                DV360 Advertiser ID
                            </label>
                        </div>

                        <div class="col-8">
                            <input pInputText maxlength="50" formControlName="DV360AdvertiserId" class="w-100" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <label>
                                Facebook Ad Account ID
                            </label>
                        </div>

                        <div class="col-8">
                            <p-inputNumber [allowEmpty]="true" [useGrouping]="false" maxlength="50" formControlName="facebookAdAccountId" class="w-100" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <label>
                                Facebook Page ID
                            </label>
                        </div>

                        <div class="col-8">
                            <p-inputNumber [allowEmpty]="true" [useGrouping]="false" maxlength="50" formControlName="facebookPageId" class="w-100" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <label>
                                Instagram ID
                            </label>
                        </div>

                        <div class="col-8">
                            <p-inputNumber [allowEmpty]="true" [useGrouping]="false" maxlength="50" formControlName="instagramId" class="w-100" />
                        </div>
                    </div>

                    <div *ngIf="formGroup.get('companyType').value == eAccountType.HMP || currentUser.permissions.isAdmin" class="mb-3">
                        <component-loader-spinner [isLoading]="loading"></component-loader-spinner>
                    
                        <div class="row mb-3 align-items-center" >
                            <div class="col-4 font-weight">
                                DMA
                            </div>
                            <div class="col-8" style="padding-right: inherit;">
                                <p-dropdown [placeholder]="'Choose'" autoWidth="false"  [style]="{'width':'100%'}" formControlName="dma" class="col-7 p-0" [options]="dmas"></p-dropdown>
                            </div>
                        </div>
                        
                        <div class="row mb-3 align-items-center">
                            <div class="col-4 font-weight">
                                City
                            </div>
                            <div class="col-8" style="padding-right: inherit;">
                                <p-multiSelect [placeholder]="'Choose'" autoWidth="false" appendTo="body" [options]="cities" [style]="{'width':'100%'}" formControlName="cities" class="col-7 p-0" [virtualScroll]="true" [itemSize]="30" selectedItemsLabel="{0} items selected"></p-multiSelect>

                            </div>
                        </div>
                        <div class="row mb-3 align-items-center" >
                            <div class="col-4 font-weight">
                                State
                            </div>
                            <div class="col-8" style="padding-right: inherit;">
                                <p-dropdown [placeholder]="'Choose'" autoWidth="false"  [style]="{'width':'100%'}" formControlName="state" class="col-7 p-0" [options]="states"></p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<user-detail #userDetailComponent [isFromAccount]="true" (onSaveClick)="saveChanges()" [showRolesDropdowns]="newUser"></user-detail>
<div class="row mb-5 mx-3 mt-3">
    <div class="col-12 d-flex justify-content-end">
        <button (click)="onCancel()" class="btn btn-light flat-btn mr-3">CANCEL</button>

        <button [disabled]="formGroupUser.invalid || formGroup.invalid || equalCompaniesName || userDetailComponent.equalUserName || userDetailComponent.equalEmail || !userDetailComponent.passwordMatch" (click)="saveChanges()" pButton label="SAVE & EXIT" class="p-button-primary"></button>
        <!-- 
        <button [disabled]="formGroupUser.invalid || formGroup.invalid" pButton label="SAVE & NEXT" class="p-button-primary"></button> -->
    </div>
</div>