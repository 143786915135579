export class Constants {
    public static FireBaseApiKey: string = '';
    public static FireBaseAuthDomain: string = '';

    public static ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100, 250, 500];
    public static ROWS_PER_PAGE_INITIAL_OPTION = 10;

    public static LANG_CODE: string = 'lang_code';
    public static DEFAULT_LANG_CODE: string = 'en';
    public static GuidEmpty: string = '00000000-0000-0000-0000-000000000000';
    public static DEFAULT_FORMAT_DATE_US = 'MM/dd/yyyy';
    public static DEFAULT_TIMEZONE = "America/Toronto";

    public static ACCOUNT_ID: string = 'accountId';
    public static IS_FROM_ACCOUNT: string = 'isFromAccount';

    // user permissions
    public static CanManageCRM: string = 'canManageCRM';
    public static CanManageAccountUsers: string = 'canManageAccountUsers';
    public static CanExecuteCampaignBuilds: string = 'canExecuteCampaignBuilds';
    public static CanCreateCampaignEmployee: string =
        'canCreateCampaignEmployee';
    public static CanCreateCampaignClient: string = 'canCreateCampaignClient';
    public static CanUploadAssetsCreatives: string = 'canUploadAssetsCreatives';
    public static CanVerifyCampaign: string = 'canVerifyCampaign';
    public static IsLearfieldClient: string = 'isLearfieldClient';
    public static IsClient: string = 'isClient';
    public static IsNotClient: string = '!isClient';
    public static IsEmployee: string = 'isEmployee';
    public static IsAdmin: string = 'isAdmin';
    public static IsOASClient: string = 'IsOASClient';
    public static IsSocialClient: string = 'IsSocialClient';

    //files
    public static ImageFileTyps = ['jpg', 'jpeg', 'png', 'gif'];
    public static VideoFileTyps = ['mp4'];
    public static AudioFileTyps = ['mp3', 'wav'];

    public static DEFAULT_FORMAT_DATE = 'MM/DD/YYYY';
    public static DEFAULT_FORMAT_DATETIME = "MM/DD/YYYY, h:mm:ss A";

    static ACCESS_TOKEN_KEY = 'access_token';
    static SERVER_SETTINGS = 'server_settings';

    static AD_IFRAME_REDIRECT_URL = '/assets/auth.html';
    static CLIENT_SETTINGS_URL = '/assets/apiSettings.json';
    static API_SETTINGS_URL = '/Application/GetSettings';

    static IS_AD_KEY = 'isAD';

    static LAST_URL_KEY = 'lastUrl';

    static PRIVACY_POLICY_LINK =
        'https://hub.mogointeractive.com/privacy-policy/';
    static REFUND_POLICY_LINK =
        'https://hub.mogointeractive.com/refund-policy/';

    //doc names on Azure
    static GoogleTagManager = 'GoogleTagManagerPlacementAccessInstructions.pdf';
    static GoogleAnalyticsAccess =
        'MogoGoogleAnalyticsAccessInstructions2019.pdf';
    static MogoCRMDataTempalte = 'MogoCRMDataTemplate.xlsx';
    static SocialFacebookInstagramAccess =
        'MogoSocialAccountAccessInstructions2019.pdf';
    static LinkedInAccess = 'LinkedInAccessInstructions.pdf';
    static YouTube = 'MogoYouTubeAccountInstructions2019.pdf';
    static Pinterest = 'MogoPinterestAccountAccessInstructions2019.pdf';
    static Snapchat = 'MogoSME Access Instructions - Snapchat.pdf';

    //info messages
    static landingPageInfo = 'After clicking on a display or Facebook ad this is the destination web page fans will be brought to.';
    static standardDisplayBannerInfo = 'These are the standard ad sizes which include 160x600, 300x250, 728x90, and 320x50. \n All campaigns should have at least one 300x250 banner. This banner has the most scale since it serves on both desktop and mobile.';
    static premiumDisplayBannerInfo = 'These will be your larger banners, the 970x250 and the 300x600. ';
    static inStreamVideoInfo = 'These are video ads that will serve on a website. They will serve before or during a video view on a website. Note in-stream video ads do NOT serve on YouTube. ';
    static connectedTvInfo = 'This is video media delivered on televisions devices. They will serve before or during a video view on a connected tv device. Note connected tv ads will NOT serve on YouTube.';
    static demographicsInfo = 'Demographic settings allow you to specify the Gender, Age, or Household Income level targeting to apply to your campaign in addition to the fan targeting. \n \n The default setting for all campaigns is to target all demographics with the fan audience.';
    // Demographic settings allow you to specify the Gender, Age, or Household Income level targeting to apply to your campaign in addition to the fan targeting. \n \n  The default setting for all campaigns is to target all demographics with the fan audience. Note to help with the scale of the Fan365 campaign you are only allowed to select one of these to target by. If you want to target more than one please reach out to your regional Fan365 support team.';

    static geographicsInfo = 'By default, all campaigns target the local area around the school.  Starting at the $24.5k investment level, campaigns can be targeted by geography. \n \n In the majority of cases, the local area is defined as the DMA the school is in. Geographic targeting is available at the State, DMA, City, or Zip Code level. Custom Zip Code targeting is only available at the $85k investment level.'
    static additionalSettingsInfo = 'These are advanced reporting and targeting features you can apply to your campaign. ';
    static siteVisitTrackingInfo = ' Site tracking allows a sponsor to understand how many visits to their webpage were preceded by a Fan365 impression. Selecting this option notifies the Fan365 activation team to assist with this setup.';
    static conversionTrackingInfo = 'Conversions are specific events that the marketer wants to occur, like orders, form submissions, etc. Conversion tracking allows a marketer to understand which of these events were driven by the campaign. Selecting this option notifies the Fan365 activation team to assist with this setup. ';
    static additionalTargerSegmentsInfo = 'If your sponsor is looking for a specific audience type, like people in-market for a car, the Regional Activation Team can help you identify and configure these audiences. \n In all cases, selecting the setting will alert the Regional Activation Team that more work is required to set up this campaign.They will reach out to you and support all next steps with the sponsor. ';
    static htmlZipInfo = 'HTML5 is a form of rich media which allows for high quality animation. HTML5 creative assets are given to the Fan365 activation team in the form of a zip folder. ';
    static contentDifficultyLevelInfo = '1 - Low-lift (1 day filming, 1 days of edit/episode)\n\n3 - Moderate (2-3 days of filming, 1-2 days of editing/episode)\n\n5- Intricate/Multifaceted (> than 2-3 days of filming & pre-pro, and >2 days of editing/episode)';
    static singleLandingPage = 'If you have a single landing page for this campaign you can add it here and it will be applied to each banner ad.';
    static dateInNoteValidation = 'Please click the "edit" button in the campaign dates box if you are trying to communicate the start and end date of the campaign. If this was not your intention you can continue by clicking "Review Upload".';

}
