import { OnInit, Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { CartItemService } from './card-item.service';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Constants } from '@Workspace/common';
import { eAccountType } from '@Workspace/_generated/enums';

@Component({
    selector: 'card-item',
    templateUrl: './card-item.component.html',
    styleUrls: ['./card-item.component.scss']
})
export class CardItemComponent implements OnInit {
    isContentArray: boolean;
    @Input('header') set header(value: string) { this._header = value; }
    @Input('content') set content(value: [any]) { this._content = value; }
    @Input('editButton') editButton: boolean = false;
    @Input() isNoteValid: boolean = true;
    @Input() baseCampaign: IBaseCampaignDto = {} as IBaseCampaignDto;
    @Input() isShareOfVoice: boolean = false;

    @Output() onEdit = new EventEmitter();

    @Input('cardType') cardType: "generic" | "social" | "landingPage" | "inventoryAddOn" | "contact" | "advertiser" | "keyValuePair" | "additionalSettings" | "paymentStatus" | "unlockFeatures" | "geographics" | "demographics" = "generic";

    additionalSettingChanged: boolean = false;
    demographicsChanged: boolean = false;
    geographicsChanged: boolean = false;
    paymentStatusChanged: boolean = false;
    @Input() contactInfo: string = null;
    constants = Constants;
    enumAccountType = eAccountType;

    constructor(private cardItemService: CartItemService) { }


    ngOnInit(): void {

        if (this.cardType == 'geographics') {
            var geoArray = this._content as string[];
            this.geographicsChanged = geoArray.some(x => x != "");
        }
        if (this.cardType == 'demographics') {
            var demoArray = this._content as string[];
            this.demographicsChanged = demoArray.some(x => x != "");
        }
        this.isContentArray = Array.isArray(this._content);
    }
    public hasDate = false;
    public _header: string;
    public _content: string | [string];
    public _flipped: boolean;

    editClicked(value: string) {
        this.onEdit.emit(value);
        if (value == 'Additional Settings') {
            this.additionalSettingChanged = true;

        } else if (value == 'Demographics') {
            this.demographicsChanged = true;
        }
        else if (value == 'Georaphical targeting') {
            this.geographicsChanged = true;
        }
        else if (value == 'Payment status') {
            this.paymentStatusChanged = true;
        }
    }
}