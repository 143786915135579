import { AbstractControl, ValidatorFn } from '@angular/forms';
import { IGridColumnComposition, IGridOptions, IPagingResult } from '@Workspace/interfaces';
import { cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Md5 } from 'ts-md5';

import { Constants } from './Constants';
import { SelectItem } from 'primeng/api';
import { ICampaignInventoryItemDto, ICreativeDto, IFileTypeDto, IUserPropertiesDto } from '@Workspace/_generated/interfaces';
import { eAccountType, eCampaignGoal, eCampaignStatus, eInventoryTypeOAS, eStandardCampaginChannel, eUserRole } from '@Workspace/_generated/enums';
import { FileUpload } from 'apps/core/src/app/modules/shared-core/components/file-upload/file-upload.component';

export class Functions {
    public static MustBeLess(
        firstControlName: string,
        secondControlName: string
    ): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const dateFrom = control.get(firstControlName).value;
            const dateTo = control.get(secondControlName).value;
            return dateFrom && dateTo && dateFrom > dateTo
                ? { startDate: { value: control.value } }
                : null;
        };
    }

    public static getExpiration(
        part: 'days' | 'hours' | 'minutes' | 'seconds',
        num?: number,
        date = new Date()
    ): Date {
        if (!num || num < 1) {
            num = 1;
        }

        switch (part) {
            case 'seconds':
                date.setSeconds(date.getSeconds() + num);
                break;
            case 'minutes':
                date.setMinutes(date.getMinutes() + num);
                break;
            case 'hours':
                date.setHours(date.getHours() + num);
                break;
            case 'days':
            default:
                date.setDate(date.getDay() + num);
                break;
        }

        return date;
    }

    public static isDate(text: any): boolean {
        if (text == null) {
            return;
        }

        if (text instanceof Date) {
            return true;
        }

        const isNumber = Functions.isNumber(text);
        if (
            new Date(text).toString() !== 'Invalid Date' &&
            !!new Date(text) &&
            !/([a-zA-Z]{3})/.exec(text) &&
            (!isNumber || text > 2674800000) &&
            (typeof text === 'string' && text.indexOf('%') === -1)
        ) {
            return true;
        } else {
            return false;
        }
    }

    public static isNumber(text: any): boolean {
        return parseFloat(text).toString() === text.toString();
    }

    public static dateToUserTimeZone(
        dateString: any,
        timeZone: string,
        formatString?: string
    ): string {
        if (!dateString) {
            return '';
        }

        if (!formatString) {
            formatString = Constants.DEFAULT_FORMAT_DATETIME;
        }

        if (!timeZone) {
            timeZone = Constants.DEFAULT_TIMEZONE;
        }

        // timeZone = moment.tz.guess();

        const date = moment(dateString);
        const dateUtc = moment.utc(dateString);

        let dateInUserTimeZone = date;

        if (!!timeZone) {
            dateInUserTimeZone = moment(dateUtc.valueOf()).tz(timeZone);
        }

        return dateInUserTimeZone.format(formatString);
    }

    public static dateTimeZoneConversion(date: Date): void {
        if(!!date){
            let timeZoneOffset = date.getTimezoneOffset();
    
            if (timeZoneOffset == 720) {
                date.setHours(0, 0, 0, 0);
            } else {
                timeZoneOffset = Math.abs(timeZoneOffset);
                let hours = Math.floor(timeZoneOffset / 60);
                let mins = timeZoneOffset - hours * 60;
                date.setHours(hours, mins, 0, 0);
            }
        }
    }

    public static sortByTimestamp(a: any, b: any): number {
        const x =
            a.dateCreatedTimestamp > 0
                ? new Date(a.dateCreatedTimestamp)
                : new Date(a.intervalStartTimestamp);
        const y =
            b.dateCreatedTimestamp > 0
                ? new Date(b.dateCreatedTimestamp)
                : new Date(b.intervalStartTimestamp);

        return x.getTime() - y.getTime();
    }

    public static parseToSelectList<T>(
        data: T[],
        labelExp: (property: T) => any,
        valueExp: (property: T) => any
    ): { label: string; value: any }[] {
        return data.map(item => {
            return {
                label: labelExp(item),
                value: valueExp(item)
            };
        });
    }

    public static parseToSelectListObservable<T>(
        dataObservable: Observable<T[]>,
        labelExp: (property: T) => any,
        valueExp: (property: T) => any
    ): Observable<{ label: string; value: any }[]> {
        return dataObservable.pipe(
            map(data => {
                return data.map(item => {
                    return {
                        label: labelExp(item),
                        value: valueExp(item)
                    };
                });
            })
        );
    }

    public static nameOf<T>(propertyExpression: (property: T) => any): string {
        const functionString = propertyExpression.toString();
        const start = functionString.lastIndexOf('.') + 1;
        const end = functionString.lastIndexOf(';');

        return functionString.substr(start, end - start);
    }

    public static iterateEnum(
        enumType,
        optional?: SelectItem,
        orderByLabel?: boolean,
        ...exludedValues: any[]
    ): SelectItem[] {
        if (!enumType) {
            return null;
        }

        let result = this.getNamesAndValues(enumType);
        result.forEach(e => (e.label = this.toSentenceCase(e.label).trim()));

        if (
            !!exludedValues &&
            Array.isArray(exludedValues) &&
            exludedValues.length > 0
        ) {
            result = result.filter(
                item => exludedValues[0].indexOf(item.value) === -1
            );
        }

        if (!!optional) {
            result = [optional, ...result];
        }

        if (!!orderByLabel) {
            result = result.sort((a, b) => {
                const x = a.label.toLowerCase();
                const y = b.label.toLowerCase();
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            });
        }

        return result;
    }

    public static toSentenceCase(e: string, space: boolean = true): string {
        if (space) {
            return e.replace(/([A-Z][a-z]|^[a-z]?)/g, "$1").replace(/[_]/g, " ");
        }

        return e.replace(/[_]/g, " ");
    }

    public static getNamesAndValues(obj, space: boolean = true): SelectItem[] {
        const result: SelectItem[] = [];
        this.getNames(obj).map(n =>
            result.push({ label: Functions.toSentenceCase(n, space), value: obj[n] })
        );

        return result;
    }

    public static getNames(e: any) {
        let names = this.getStringObjectValues(e).filter(
            v => typeof v === "string"
        ) as string[];

        return names;
    }

    public static getStringObjectValues(obj) {
        return this.getAllObjectValues(obj).filter(
            v => typeof v === 'string'
        ) as string[];
    }

    public static getAllObjectValues(obj): (number | string)[] {
        return Object.keys(obj).map(key => obj[key]);
    }

    public static getEnumName(
        e: any,
        value: number,
        space: boolean = true
    ): string {
        if (!e || !value) {
            return undefined;
        }

        return this.getNamesAndValues(e, space)
            .filter(i => i.value === (value as number))
            .map(i => i.label)[0] as string;
    }

    static base64ToBlob(
        base64: string,
        contentType: string,
        sliceSize: number = 512
    ) {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    public static cloneObject(obj: any) {
        const cln = cloneDeep(obj);

        return cln;
    }

    public static isWindow() {
        return !window.frameElement;
    }

    public static getGravatarPicture(email: string): string {
        return (
            'https://www.gravatar.com/avatar/' + Md5.hashStr(email) + '?d=mp'
        );
    }

    public static composeGridOptions(
        options: IGridOptions,
        sortField: string,
        data: IPagingResult | string,
        isSimpleview: boolean = true,
        columns?: IGridColumnComposition[],
        isOData: boolean = true
    ): IGridOptions {
        if (!!sortField)
            options.sortField = sortField;
        options.isSimpleView = isSimpleview;
        options.isOData = isOData;

        if (typeof data === 'string') {
            options.data = data;
        } else {
            options.data = data;
        }

        if (!!columns) {
            options.columns = [...columns];
        }

        return options;
    }

    public static getThumbnailRelativePath(slideType: number): string {
        return `./assets/pictures/slide-templates-temp/slide-template-${slideType}.png`;
    }

    public static IsFileValid(file: any, attachmentType: IFileTypeDto): boolean {
        // check size
        if (file.size > attachmentType.fileMaxSizeInBytes)
            return false;
        // check type
        var fileType = file.name.split('.')[1];
        var attachmentFileTypes = attachmentType.fileTypeCsv.split(',');
        var containsFileType = false;
        for (let type of attachmentFileTypes) {
            if (type == fileType)
                containsFileType = true;
        }
        if (!containsFileType)
            return false;

        return true;
    }

    public static replaceSpecialCharacters(attribute) {
           attribute = attribute.replace(/'/g, "''");
      
           attribute = attribute.replace(/%/g, "%25");
           attribute = attribute.replace(/\+/g, "%2B");
           attribute = attribute.replace(/\//g, "%2F");
           attribute = attribute.replace(/\?/g, "%3F");
      
           attribute = attribute.replace(/#/g, "%23");
           attribute = attribute.replace(/&/g, "%26");
           return attribute;
    }
    
    public static isTodayOrBefore(date1: Date){
        if(!date1)
            return false;

        let date2=new Date();
        date1.setHours(0,0,0,0);
        date2.setHours(0,0,0,0);
        return date1<=date2;
    }

    public static isDateBefore(date1: Date, date2: Date){
        if(!date1 || !date2)
            return false;
        date1.setHours(0,0,0,0);
        date2.setHours(0,0,0,0);
        return date1<date2;
    }

    public static isOneDayDifference(date1: Date, date2: Date){
        date1.setHours(0,0,0,0);
        date2.setHours(0,0,0,0);
        const timeDiff = date2.getTime() - date1.getTime();
        const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        return daysDiff == 1;

    }

    public static checkCanChangeAsset(creativeGroup: number, creativeFlightStartDate: Date, campaignStartDate: Date, campaignStatusId: number, statusId: number, 
                                        campaignType: eAccountType, currentUser: IUserPropertiesDto, isBuilt: boolean, campaignInventoryItem: ICampaignInventoryItemDto, maxNumberOfFlights: number=0)
    {

        if(!!isBuilt || statusId == eCampaignStatus.Sent_to_API_Build){
            return false;
        }
        let creativeStartDate: Date;

        if(creativeGroup==1)
            creativeStartDate = campaignStartDate ? new Date(campaignStartDate) : null;
        else
            creativeStartDate = creativeFlightStartDate ? new Date(creativeFlightStartDate) : null;
            
        if (campaignType == eAccountType.HMP 
            && (campaignStatusId == eCampaignStatus.Payment_Completed || campaignStatusId == eCampaignStatus.Campaign_Ended || campaignStatusId == eCampaignStatus.Campaign_Expired
                || campaignStatusId == eCampaignStatus.Campaign_Live || campaignStatusId == eCampaignStatus.Campaign_Paused 
                || statusId == eCampaignStatus.Sent_to_API_Build  || statusId == eCampaignStatus.Build_Success)) {
            return false;
        }    

        if(currentUser.permissions.isClient && currentUser.userRole != eUserRole.LIMGCSocialAdOps && currentUser.userRole != eUserRole.LIMGC_AdOps_Admin){
            if(((campaignType == eAccountType.OAS && campaignInventoryItem?.inventoryItemOAS?.inventoryType != eInventoryTypeOAS.Share_Of_Voice) || campaignType == eAccountType.Social || campaignType == eAccountType.Content) && ((campaignStatusId != eCampaignStatus.Assets_Pending && statusId != eCampaignStatus.Assets_Pending
             && campaignStatusId != eCampaignStatus.Creative_Swaps && statusId != eCampaignStatus.Creative_Swaps) || this.isTodayOrBefore(creativeStartDate))) 
                return false;
            else if(campaignType == eAccountType.FAN365 && ((campaignStatusId != eCampaignStatus.Assets_Pending && statusId != eCampaignStatus.Assets_Pending
                && campaignStatusId != eCampaignStatus.Creative_Swaps && statusId != eCampaignStatus.Creative_Swaps 
                && campaignStatusId != eCampaignStatus.Assets_Declined  && statusId != eCampaignStatus.Assets_Declined
                && campaignStatusId != eCampaignStatus.Assets_Uploaded && statusId != eCampaignStatus.Assets_Uploaded)
                || (this.isTodayOrBefore(creativeStartDate) && maxNumberOfFlights != 1) ))
                return false;
            else 
                return true;
        }
        else   
            return true;
    }

    public static checkCanChangeFlight(campaignStatusId: number, statusId: number, campaignType: eAccountType, isBuilt: boolean){
        //let creativeStartDate: Date;

        // if(creativeGroup==1)
        //     creativeStartDate = campaignStartDate ? new Date(campaignStartDate) : null;
        // else
        //     creativeStartDate = creativeFlightStartDate ? new Date(creativeFlightStartDate) : null;
            
        //if(this.isTodayOrBefore(creativeStartDate)){
        if(isBuilt){
            return false;
        }  
        else if((campaignType == eAccountType.OAS || campaignType == eAccountType.Social || campaignType == eAccountType.Content) 
            && ((campaignStatusId != eCampaignStatus.Assets_Pending && campaignStatusId != eCampaignStatus.Creative_Swaps
                    && statusId != eCampaignStatus.Assets_Pending && statusId != eCampaignStatus.Creative_Swaps)
                || statusId == eCampaignStatus.Sent_to_API_Build))
            return false;
        else if(campaignType == eAccountType.FAN365 
                    && ((campaignStatusId != eCampaignStatus.Assets_Pending && campaignStatusId != eCampaignStatus.Creative_Swaps 
                            && campaignStatusId != eCampaignStatus.Assets_Declined && campaignStatusId != eCampaignStatus.Assets_Uploaded
                            && statusId != eCampaignStatus.Assets_Pending && statusId != eCampaignStatus.Creative_Swaps 
                            && statusId != eCampaignStatus.Assets_Declined && statusId != eCampaignStatus.Assets_Uploaded)
                        || statusId == eCampaignStatus.Sent_to_API_Build))
            return false;
        else 
            return true;
    }

    public static ifFileExist(creative: ICreativeDto): boolean{
        return creative.creativeAssets.filter(x => x.file != null).length > 0;
    }

    public static formatDateToYYYYMMDD(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    public static isDatesEqual(date1: Date, date2: Date){
        date1.setHours(0,0,0,0);
        date2.setHours(0,0,0,0);
        if(date1==null && date2==null)
            return true;
        else if(date1==null || date2==null)
            return false;
        else 
            return date1.getUTCFullYear()==date2.getUTCFullYear() && date1.getUTCMonth() == date2.getUTCMonth() && date1.getUTCDate() == date2.getUTCDate();
    }

    public static isDateBetween(date: Date, startDate: Date, endDate: Date, isEndDate: boolean = null){
        if(!date || !startDate || !endDate)
            return false;
        date.setHours(0,0,0,0);
        startDate.setHours(0,0,0,0);
        endDate.setHours(0,0,0,0);
        if(isEndDate == false)
            return date >= startDate && date < endDate;
        else if(isEndDate == true)
            return date > startDate && date <= endDate;
        else
            return date > startDate && date < endDate;
    }

    public static addBusinessDays(d, n) {
        d = new Date(d.getTime());
        var day = d.getDay();
        d.setDate(d.getDate() + n + (day === 6 ? 2 : +!day) + (Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2));
        return new Date(d);
    }

    public static areHmpCampaignDatesInvalid(startDate: Date, endDate: Date, campaignGoalType){
        // variables for start date validation
        let today = new Date();
        let todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        var minStartDate = Functions.addBusinessDays(todayDateOnly, 5);

        // variables for end date validation
        let daysToAddMin = campaignGoalType == eCampaignGoal.Quick_Promotion ? 2 : 20;
        let daysToAddMax = campaignGoalType == eCampaignGoal.Quick_Promotion ? 13 : 83;
        let minEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + daysToAddMin);
        let maxEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + daysToAddMax);

        return startDate < minStartDate || endDate < minEndDate || endDate > maxEndDate; 
    }

    public static returnAcceptedBannerSizes(campaignType: eAccountType, creatives: ICreativeDto[], currentCreative: ICreativeDto, fileUpload: FileUpload, defaultBannerSizes: string){
        if(campaignType == eAccountType.FAN365 && currentCreative.creativeGroup > 1){
            let acceptedBannerSizes = "";
            let firstCreative = creatives.find(x => x.creativeGroup == 1 && x.creativeTypeId == currentCreative.creativeTypeId);
            firstCreative.creativeAssets.forEach(x => {
                if(x.height !=0 && x.width != 0){
                    let dimension = x.width + "x" + x.height;
                    if(dimension =='1080x1080' && currentCreative.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed)
                        dimension = "1080x1080(recommended)";
                    if(!acceptedBannerSizes.includes(dimension))
                        acceptedBannerSizes += dimension + ", ";
                }
            });
            acceptedBannerSizes = acceptedBannerSizes.slice(0, acceptedBannerSizes.length - 2);
            if(acceptedBannerSizes == "")
                acceptedBannerSizes = "You need to upload assets on first flight.";
            return acceptedBannerSizes;
        }
        else
            return defaultBannerSizes;
    }
}

