import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Observer, Observable } from 'rxjs';
import { componentFadeIn } from '../../route-animations';
import { IGridOptions, IPagingResult } from '@Workspace/interfaces';
import { Functions, Constants } from '@Workspace/common';
import { AccountController, CampaignController} from '@Workspace/_generated/services';
import { eCampaignStatus, eAccountType, eUserRole, eEntityStatus, eRegion, eChannelType, eApiBuildStatus, eFilterDefiniton } from '@Workspace/_generated/enums';
import { PageLoaderService, SettingsService, StorageService } from '@Workspace/services';
import { IUserPropertiesDto, IAccountDto, IBaseCampaignDto, ICampaignBulkSettingsDto, ISeasonMappingDto } from '@Workspace/_generated/interfaces';
import { AuthService } from '@Workspace/auth';
import { CampaignService } from '../../services/campaign.service';
import { take, takeUntil } from 'rxjs/operators';
import { CartItemService } from '../../../shared-core/components/card-item/card-item.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadService } from '../../services/file-upload.service';
import * as moment from 'moment';
import { QueryBuilder } from 'odata-query-builder';
import { GridComponent } from '../../../shared-core/components/grid/rc-grid.component';
import * as _ from 'lodash';
import { CampaignTransferDataDialog } from '../campaign-transfer-data-dialog/campaign-transfer-data-dialog';
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'campaign-list-component',
    templateUrl: './campaign-list.component.html',
    styleUrls: ['./campaign-list.component.scss'],
    animations: [componentFadeIn]
})
export class CampaignListComponent implements OnInit, OnDestroy {

    constructor(
        public accountController: AccountController,
        public campaignController: CampaignController,
        public pageLoader: PageLoaderService,
        private settingsService: SettingsService,
        private campaignService: CampaignService,
        private messageService: MessageService,
        private router: ActivatedRoute,
        private route: Router,
        private authService: AuthService,
        private confirmationService: ConfirmationService,
        private storageService: StorageService
    ) {
    }
    @Input() status: SelectItem;
    @Input() gridId: number;
    @Input() options: IGridOptions;
    @Input() currentAccount: IAccountDto[];
    @Input() selectedSponsorInput: string = '';
    @Input() clientNameFilter: string = '';
    @Input() fiscalYearFilter: string[] = [];
    @Input() showCheckboxes: boolean = false;
    @Output() onAfterDataLoadList: EventEmitter<any> = new EventEmitter();
    @Output() selectedCampaignsEmmiter: EventEmitter<any> = new EventEmitter();
    @Output() updateBreadCrumb: EventEmitter<any> = new EventEmitter();
    @Output() applyFilters: EventEmitter<any> = new EventEmitter();
    @ViewChild(GridComponent) GridComponent: GridComponent;
    @ViewChild('campaignTransferDataDialog') campaignTransferDataDialog: CampaignTransferDataDialog;
    
    loaded: boolean = false;
    constants = Constants;
    enumCampaignStatus = eCampaignStatus;
    enumAccountType = eAccountType;
    enumUserRole = eUserRole;
    enumEntityStatus = eEntityStatus;
    ngUnsubscribe = new Subject();
    currentUser: IUserPropertiesDto = this.authService.userSettings;
    searchParameter: string = '';
    searchParameterSponsor: string = '';
    grids: SelectItem[] = [];
    showGrid: boolean = true;
    enumCampaignRegion = eRegion;
    isCampaignLive: boolean;
    displayBasic: boolean = false;
    unlock: boolean = false;
    //options: IGridOptions;
    nameEllipsesState: { [id: string]: boolean } = {};
    selectedCampaigns: ICampaignBulkSettingsDto[] = [];
    isAllRowsChecked: boolean = false;
    campaignBulkSettings: ICampaignBulkSettingsDto;
    splitButtonMenu: MenuItem[];
    seasons: ISeasonMappingDto[];

    skipParam: number = 0;
    takeParam: number = 10;
    sortOrder: number = 0;
    sortField: string = '';
    selectedSponsor: string = '';
    dealId: string = '';
    items: MenuItem[];
    gridList: any[] = [];
    faClipboard: any = faClipboard;

    campaignsGroupedBySponsor = {} as IPagingResult;

    async ngOnInit() {
        this.router.paramMap.subscribe(params => {
            if(params.get('sponsor')){
                this.selectedSponsorInput = params.get('sponsor');
                if(localStorage.getItem('sponsor') == null){
                    this.updateBreadCrumb.emit(params.get('sponsor'));
                }
            }
         })

         if(localStorage.getItem('sponsor') != null){
                this.selectedSponsorInput = localStorage.getItem('sponsor');
                this.updateBreadCrumb.emit(localStorage.getItem('sponsor'));
         }

        this.campaignService.getGridIdId().pipe(takeUntil(this.ngUnsubscribe)).subscribe(async x => {
            this.gridId = x;
            this.selectedSponsor = this.selectedSponsorInput;
            if(!!this.gridId){
                await this.GetSeasonYear();
                if(((this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isOASClient || this.currentUser.permissions.isSocialClient) 
                    && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin)  && !this.selectedSponsor){
                    if(localStorage.getItem('gridFilter') == null || localStorage.getItem('gridFilter') == "" || localStorage.getItem('gridFilter') == "null"){
                        await this.composeGroupedGrid();
                    }
                }
                else{
                    if(localStorage.getItem('gridFilter') == null || localStorage.getItem('gridFilter') == "" || localStorage.getItem('gridFilter') == "null"){
                        await this.composeGridColumns();
                    } 
                }
            }
        });
            //await this.composeFilter(); 
    }

    getMenuItemsForItem(rowData: IBaseCampaignDto) {
        if(!this.gridList.some(x=> x.campaignId == rowData.id)){
            this.splitButtonMenu = this.fillSplitButtonMenu(rowData);

            let showMenu = false;
            this.splitButtonMenu.forEach(v => {
                if (v.visible == true) {
                    showMenu = true;
                }
            });

            this.gridList.push({
                "campaignId": rowData.id, "isVisible": showMenu, "splitButtonMenu": this.splitButtonMenu
            });
            return [this.gridList.filter(x=>x.campaignId == rowData.id)[0].splitButtonMenu, this.gridList.filter(x=>x.campaignId == rowData.id)[0].isVisible]; // returns menuItem[] and property for visibility of menu icon 

        }
        else if(this.gridList.some(x=> x.campaignId == rowData.id && rowData.entityStatus == eEntityStatus.Transfered)){
            this.splitButtonMenu = this.fillSplitButtonMenu(rowData);

            let showMenu = false;
            this.splitButtonMenu.forEach(v => {
                if (v.visible == true) {
                    showMenu = true;
                }
            });
            this.gridList.filter(x=>x.campaignId == rowData.id).forEach(x=>{
                x.isVisible = showMenu;
                x.splitButtonMenu = this.splitButtonMenu;
            });
            return [this.gridList.filter(x=>x.campaignId == rowData.id)[0].splitButtonMenu, this.gridList.filter(x=>x.campaignId == rowData.id)[0].isVisible]; // returns menuItem[] and property for visibility of menu icon 
        }
        return [this.gridList.filter(x=>x.campaignId == rowData.id)[0].splitButtonMenu, this.gridList.filter(x=>x.campaignId == rowData.id)[0].isVisible];
      }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    handleEllipseAdded(ellipseState: boolean, id: string) {
        this.nameEllipsesState[id] = ellipseState;
    }
    checkIsCampaignLive(rowData: IBaseCampaignDto): boolean {
        if (rowData.campaignStatusId == eCampaignStatus.Campaign_Live)
            return true
        else
            return false
    }

    async composeGroupedGrid(clientName: string = ''){
        this.clientNameFilter = clientName != '' ? clientName : this.clientNameFilter;
        if(localStorage.getItem('gridFilter') != null && localStorage.getItem('gridFilter') != "" && localStorage.getItem('gridFilter') != "null"){
            var sessionValue = JSON.parse(localStorage.getItem('gridFilter'));
           
            if(sessionValue['searchParameterSponsor'])
            {
                this.searchParameterSponsor = sessionValue['searchParameterSponsor'];
            }
        }
       
        this.options = {} as IGridOptions;
        this.options.isOData = false;
        this.options.columns = [
            {
                label: 'Sponsor',
                value: 'sponsor',
                width: '23%'
            },
            {
                label: 'Deal Number',
                value: 'dealId',
            },
            {
                label: 'Season',
                value: 'seasonYear',
                width: '10%'
            },
            {
                label: 'Last Modified',
                value: 'lastModified',
            },
            {
                label: 'Season Impression',
                value: 'seasonImpression',
                width: '11%'
            },
            {
                label: 'Revenue',
                value: 'revenue',
            },
            {
                label: 'Pending Campaigns',
                value: 'pendingCampaigns',
            }
        ];

        this.pageLoader.showPageLoader();
        //await this.GetSeasonYear();
        this.campaignController.GetCampaignGroupedBySponsor(this.clientNameFilter, this.fiscalYearFilter.toString(), this.searchParameterSponsor, 'sponsor', 1, '', this.skipParam, this.takeParam).pipe(take(1)).subscribe(
            result => {
                this.options.data = result;
                this.GridComponent.value = this.options.data;
                this.pageLoader.hidePageLoader();
            },
            error => {}
        );
    }

    async composeGridColumns(event?, isFromComposeFilter: boolean = false) {
        if (!!event)
            this.gridId = event;

            if(this.options == null || this.options == undefined){
                this.options = {} as IGridOptions;
            }

        if(this.currentUser.userRole == 1 && this.currentUser.accountId == null  
            && this.currentUser.permissions.isLearfieldClient == false && this.currentUser.permissions.isHMPClient == false 
            && this.currentUser.permissions.isHSClient == false && this.currentUser.permissions.isOASClient == false 
            && this.currentUser.permissions.isSocialClient == false && this.currentUser.permissions.isClient == false)
            {
                return; 
            }

        if (this.gridId == 1 || this.gridId == 4 || this.gridId == 5 || this.gridId == 6|| this.gridId == 7)
        {
            this.options.columns = [
                {
                    label: '',
                    value: '',
                    width: '1%'
                },
                {
                    label: 'Name',
                    value: 'name',
                    width: '23%'
                },
                {
                    label: 'Property',
                    value: 'accountName',
                    width: '10%'
                },
                {
                    label: 'Deal Number',
                    value: 'dealName',
                    width: '10%'
                },
                {
                    label: 'Last Modified',
                    value: 'modifiedOn',
                    width: '10%'
                },
                {
                    label: 'Impressions',
                    value: 'impressions',
                    align: 'right',
                },
                {
                    label: 'Inventory Items',
                    value: 'inventoryItemCount',
                    align: 'right',
                    width: '9%'
                },
                {
                    label: 'Channel',
                    value: 'campaignType',
                },
                {
                    label: 'Region',
                    value: 'regionId',
                },
                {
                    label: 'Status',
                    value: 'statusId',
                    width: '7%'
                },
                {
                    label: 'Report',
                    value: '',
                    align: 'center',
                },
                {
                    label: 'Action',
                    value: '',
                    align: 'right'
                }
            ];
            if(this.gridId == 6) 
            {
                this.options.columns.splice(3, 0, 
                    {
                        label: 'Campaign Type',
                        value: 'campaignType',
                        width: '10%'
                    });
            } else 
            {
                this.options.columns.splice(5, 0,  
                    {
                    label: 'Advertiser Created',
                    value: 'createdOn',
                    width: '10%'
                });
            }
        }
        else if (this.gridId == 2)
            this.options.columns = [
                {
                    label: 'Name',
                    value: 'name',
                    width: '23%'
                },
                {
                    label: 'Client Name',
                    value: 'accountName',
                    width: '13%'
                },
                {
                    label: 'Last Modified',
                    value: 'modifiedOn',
                    width: '13%'
                },
                {
                    label: 'Status',
                    value: 'statusId'
                },
                {
                    label: 'Report',
                    value: '',
                    align: 'center',

                },
                {
                    label: 'Action',
                    value: '',
                    align: 'right',
                    width: '5%'
                }
                // {
                //     label: 'Live',
                //     value: '',
                //     align: 'right',
                //     width: '5%'
                // }
            ];

        else
            this.options.columns = [
                {
                    label: 'Name',
                    value: 'name',
                    width: '23%'
                },
                {
                    label: 'Client Name',
                    value: 'accountName',
                    width: '13%'
                },
                {
                    label: 'Last Modified',
                    value: 'modifiedOn',
                    width: '13%'
                },
                {
                    label: 'Total',
                    value: 'totalAmount',
                    align: 'right'
                },
                {
                    label: 'Status',
                    value: 'statusId'
                },
                {
                    label: 'Report',
                    value: '',
                    align: 'center',
                },
                {
                    label: 'Action',
                    value: '',
                    align: 'right'
                }
            ];

        if (!!this.currentAccount) {

            if (this.gridId == 1 || this.gridId == 4 || this.gridId == 5 || this.gridId == 6|| this.gridId == 7) {
                this.options.columns.splice(1, 1);
                this.options.columns.splice(6, 2);
            }
            else {
                this.options.columns.splice(1, 1);
            }
        }
        if (this.currentUser.permissions.isClient) {
            if (this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isOASClient || this.currentUser.permissions.isSocialClient
                || this.currentUser.userRole == eUserRole.LIMGCSocialAdOps || this.currentUser.userRole == eUserRole.LIMGC_AdOps_Admin
            ) {
                this.options.columns.splice(this.options.columns.length - 1, 1);
            }
            else if (this.currentUser.permissions.isHSClient) {
                this.options.columns.splice(this.options.columns.length - 1, 1);
            }
        }

        if(!isFromComposeFilter){
            await this.composeFilter(null, null, 0, 10, !!this.currentAccount && this.currentAccount.length == 1 ? this.currentAccount[0].id : null);
        }
        
        if(localStorage.getItem('gridFilter') == null || localStorage.getItem('gridFilter') == "" || localStorage.getItem('gridFilter') == "null")
        {
            Functions.composeGridOptions(
                this.options,
                'Name',
                this.settingsService.createApiUrl('Campaign/GetAllCampaigns'),
                false
            );
        }

        this.options = _.cloneDeep(this.options);
    }

    public checkData(event) {
        if (event.totalCount)
            this.showGrid = false;
    }

    public async composeFilter(searchParameter?: string, gridFilters?: string, skip?: number, take?: number, clientNameFilter?: string) {
        this.clientNameFilter = clientNameFilter != null ? clientNameFilter : this.clientNameFilter;
        if(!this.options){
           
            if(((this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isOASClient || this.currentUser.permissions.isSocialClient) 
            && this.currentUser.userRole != eUserRole.LIMGCSocialAdOps && this.currentUser.userRole != eUserRole.LIMGC_AdOps_Admin)  && !this.selectedSponsor){
                    await this.composeGroupedGrid();
            }
            else{
                    await this.composeGridColumns(null, true);
            }
        }

        if(take){
            this.skipParam = skip;
            this.takeParam = take;
            this.GridComponent.resetSkipTake();
        }
        if (this.gridId == 1)
            this.options.filter = `CampaignType eq Learfield.Mogo_Fan365.Common.Enums.eAccountType'${eAccountType.FAN365}'`;
        else if (this.gridId == 2)
            this.options.filter = `Account/TypeId eq Learfield.Mogo_Fan365.Common.Enums.eAccountType'${eAccountType.HS}'`;
        else if (this.gridId == 4)
            this.options.filter = `CampaignType eq Learfield.Mogo_Fan365.Common.Enums.eAccountType'${eAccountType.OAS}'`;
        else if (this.gridId == 5) {
            this.options.filter = `CampaignType eq Learfield.Mogo_Fan365.Common.Enums.eAccountType'${eAccountType.Social}'`;
        }
        else if (this.gridId == 6){
            if(this.currentUser.userRole == eUserRole.LIMGCSocialAdOps)
                this.options.filter = 
                    `(CampaignType eq Learfield.Mogo_Fan365.Common.Enums.eAccountType'${eAccountType.Social}'
                    or CampaignType eq Learfield.Mogo_Fan365.Common.Enums.eAccountType'${eAccountType.Content}')`;
            else
                this.options.filter = '';

        }
        else if (this.gridId == 7) {
            this.options.filter = `CampaignType eq Learfield.Mogo_Fan365.Common.Enums.eAccountType'${eAccountType.Content}'`;
        }
        else
            this.options.filter = `Account/TypeId eq Learfield.Mogo_Fan365.Common.Enums.eAccountType'${eAccountType.HMP}'`;


        if (!!this.status) {
            if (this.status.value == 1)
                this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : ' ') +
                    (this.currentUser.permissions.isClient ?
                        ` (CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Assets_Pending}' 
                        or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Assets_Declined}'
                        or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Creative_Swaps}'
                        or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Payment_Pending}')` :

                        ` (CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Assets_Uploaded}' 
                        or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Assets_Approved}' 
                        or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Creative_Swaps}'
                        or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Payment_Completed}' 
                        or 
                         (StatusId ne ${null}
                             and CampaignStatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Live}' 
                             and CampaignStatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Paused}'
                             and CampaignStatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Ended}'
                             and CampaignStatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Expired}') 
                         )`)

            else if (this.status.value == 2)
                this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : ' ') +
                    (this.currentUser.permissions.isClient ?
                        ` (CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Draft}' 
                or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Assets_Uploaded}' 
                or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Assets_Approved}' 
                or 
                 ((CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Live}' 
                 or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Paused}') 
                 and StatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Sent_to_API_Build}'))` :

                        ` (CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Assets_Pending}'
                or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Assets_Declined}'
                or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Payment_Pending}' 
                or
                 ((CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Live}' 
                 or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Paused}') 
                 and StatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Sent_to_API_Build}'))`
                    )

            else if (this.status.value == 3)
                this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : '') +
                    ` (CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Ended}' or CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Expired}')`;
            
        }
        
        if (localStorage.getItem('gridFilter') != null && localStorage.getItem('gridFilter') != "" && localStorage.getItem('gridFilter') != "null" && this.currentUser.permissions.isClient){
            var sessionValue = JSON.parse(localStorage.getItem('gridFilter'));
           
            let campaignStatusFilter;

            if(sessionValue['campaignStatus'])
            {
                campaignStatusFilter = sessionValue['campaignStatus'];

                this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : ' ') +
                ` (CampaignStatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${campaignStatusFilter}'
                or (CampaignStatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'14' and CampaignStatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'8' and
                    StatusId eq Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${campaignStatusFilter}'))`;
            }
        }

        if (this.currentUser.permissions.isHSClient) {

            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : ' ') +
                ` CampaignStatusId ne Learfield.Mogo_Fan365.Common.Enums.eCampaignStatus'${eCampaignStatus.Campaign_Draft}'`;
        }

        if (!!this.clientNameFilter && (gridFilters == null || !gridFilters.includes("Account/Id eq "))) {
            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ` and ` : ` `);
            this.options.filter += ` Account/Id eq ${this.clientNameFilter}`;
        }

        if(this.fiscalYearFilter.length==0 && this.gridId != 2 && this.gridId !=3)
            await this.GetSeasonYear();
        
        if(!!this.selectedSponsor)
            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : '' ) + ` (Sponsor eq '${Functions.replaceSpecialCharacters(this.selectedSponsor)}') `;

        if(!!this.dealId)
            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : '') +  ` (DealId eq '${Functions.replaceSpecialCharacters(this.dealId)}') `;

        if (!!gridFilters && gridFilters != '')
            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : '') + gridFilters;
        else if(this.gridId != 2 && this.gridId !=3)
            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : '') + ` SeasonMapping/SeasonYear in ('${this.fiscalYearFilter.toString().split(',').join("','")}')`;

        if (this.gridId == 2 || this.gridId == 3) {
            if (this.fiscalYearFilter.length == 0 || !this.seasons) {
                await this.GetSeasonYear();
            }
            let seasons = this.seasons.filter(s => this.fiscalYearFilter.includes(s.seasonYear));
            seasons.sort((x, y) => new Date(x.startDate).getTime() - new Date(y.startDate).getTime());
            let years =  Object.values(seasons.reduce((result, item) => {
                if (!result[item.seasonYear]) {
                    result[item.seasonYear] = [];
                }
                result[item.seasonYear].push(item); 
                return result;
            }, {}));
            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and' : ' ');
            years.map((y, i)=> {
                this.options.filter += (i != 0 ? ' or ' : ' (') + `(StartDate ge ${Functions.formatDateToYYYYMMDD(new Date(y[0].startDate))}`;
                this.options.filter += ' and' + ` StartDate le ${Functions.formatDateToYYYYMMDD(new Date(y[3].endDate))})`;
                this.options.filter += ' or' + ` (EndDate ge ${Functions.formatDateToYYYYMMDD(new Date(y[0].startDate))}`;
                this.options.filter += ' and' + ` EndDate le ${Functions.formatDateToYYYYMMDD(new Date(y[3].endDate))})`;
                this.options.filter += ' or' + ` (StartDate le ${Functions.formatDateToYYYYMMDD(new Date(y[0].startDate))}`;
                this.options.filter += ' and' + ` EndDate ge ${Functions.formatDateToYYYYMMDD(new Date(y[3].endDate))})`;
            })
            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ')' : ' ');
        }

            //this.searchParameter = searchParameter;
        if (!!searchParameter || !!this.searchParameter) {
            // const searchTerm = !!searchParameter ? searchParameter.replace(
            //     /\'/g,
            //     "''"
            // ) : this.searchParameter.replace(
            //     /\'/g,
            //     "''"
            // );
            const searchTerm = !!searchParameter ? searchParameter : this.searchParameter;

            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : ' ') +
                ` (contains(Name, '${Functions.replaceSpecialCharacters(searchTerm)}') or contains(DealName, '${Functions.replaceSpecialCharacters(searchTerm)}'))`;

        }
        else
            this.options.filter += (this.options.filter != null && this.options.filter != '' ? ' and ' : ' ') +  ` contains(Name, '')`;

        let options = this.options;
        this.options = Object.assign({}, options);

        if(localStorage.getItem('gridFilter') != null && localStorage.getItem('gridFilter') != "" && localStorage.getItem('gridFilter') != "null")
        {    
            if(!((this.currentUser.permissions.isLearfieldClient || this.currentUser.permissions.isOASClient || this.currentUser.permissions.isSocialClient) && !this.selectedSponsor))
            {
                Functions.composeGridOptions(
                    this.options,
                    'Name',
                    this.settingsService.createApiUrl('Campaign/GetAllCampaigns'),
                    false
                );
            }
        }
    }

    async GetSeasonYear()
    {
        await this.campaignController.GetAllSeasonMapping().toPromise()
        .then(
            data=>{
                this.seasons = data;
                if(data.length>0){
                    data.forEach(x => {
                        let compareDate = moment(new Date());
                        let startDate   = moment(x.startDate);
                        let endDate     = moment(x.endDate);
        
                        if(compareDate.isBetween(startDate, endDate) && this.fiscalYearFilter.length == 0)
                            this.fiscalYearFilter=[x.seasonYear];
                    });
                }
            }
        );        
    }

    getCampaignChannels(campaign: IBaseCampaignDto) {
        let channels;
        let creativesSocial = campaign.creatives.some(x => x.channelId == eChannelType.Social);
        let creativesDisplay = campaign.creatives.some(x => x.channelId != eChannelType.Social);

        // if(campaign.entityStatus == eEntityStatus.Transfered){
        //     this.campaignController.GetTransferedCampaign(campaign.id).pipe(take(1)).subscribe(x =>{
        //         creativesSocial = x.data[0].creatives.some(x => x.channelId == eChannelType.Social);
        //         creativesDisplay = x.data[0].creatives.some(x => x.channelId != eChannelType.Social);
        //     });
        // }

        if (creativesSocial && creativesDisplay)
            channels = 'Display; Facebook';
        else if (creativesDisplay)
            channels = 'Display';
        else if (creativesSocial)
            channels = 'Facebook';
        else
            channels = '';

        return channels;
    }
    getTextAlignClass(textAlign: string): string {
        let textAlignClass: string = "text-left";
        switch (textAlign) {
            case 'left':
                textAlignClass = "text-left";
                break;
            case 'center':
                textAlignClass = "text-center";
                break;
            case 'right':
                textAlignClass = "text-right";
                break;
        }
        return textAlignClass;
    }

    navigateToReport(rowData: IBaseCampaignDto) {
        return "/#/campaign/" + rowData.id + "/reporting";
    }

    navigateToSponsor(rowData: IBaseCampaignDto){
        return "/#/fan365/" + rowData.sponsor.replace('/','%2F');
    }

    openEditDialog(rowData: IBaseCampaignDto, isHref: boolean = false) {
        if(!isHref){
            this.storageService.set("isEdit", true);
        }
        if (rowData.account.typeId == eAccountType.HMP)
            return "/#/campaign/" + rowData.id + "/";
        else if (rowData.account.typeId == eAccountType.HS)
            return "/#/su-campaign/" + rowData.id + "/";
    }
    editCampaign(rowData: IBaseCampaignDto) {
        this.displayBasic = true;
    }
    onChangeGrid(event) {
        this.gridId = event.value;
        this.composeGridColumns();
    }
    
    updateCampaignService(rowData: IBaseCampaignDto) {
        if(rowData.entityStatus == eEntityStatus.Transfered){
            return "/#";
        }
        else if (rowData.account.isLearfieldCampaign || rowData.account.isOASCampaign || rowData.account.isSocialCampaign || rowData.account.isContentCampaign)
            return "/#/campaign/" + rowData.id + "/campaign-upload-fan365";
        else if (rowData.account.isCampaignCreation && this.currentUser.permissions.isClient)
            return "/#/campaign/" + rowData.id;
        else 
            return "/#/campaign/" + rowData.id + "/campaign-upload";
    }

    openTransferedCampaignModal(event: Event, id: string){
        event.preventDefault();
        this.campaignController.GetTransferedCampaign(id).pipe(take(1)).subscribe(x =>{
            if(x.data){
                this.confirmationService.confirm({
                    header: 'Campaign Transfered',
                    key: 'transferedCampaign',
                    message: 'This campaign has been transfered to <strong>' + x.data[0].name + '</strong>',
                    rejectVisible: true,
                    rejectLabel: 'Close',
                    acceptLabel: 'Open Target Campaign',
                    accept: async () => {
                        this.confirmationService.close();
                        setTimeout(() => {
                            if (x.data[0].account.isLearfieldCampaign || x.data[0].account.isOASCampaign || x.data[0].account.isSocialCampaign || x.data[0].account.isContentCampaign)
                                this.route.navigate(['/campaign/' + x.data[0].id + '/campaign-upload-fan365']);
                            else if (x.data[0].account.isCampaignCreation && this.currentUser.permissions.isClient)
                                this.route.navigate(['/campaign/' + x.data[0].id]);
                            else
                                this.route.navigate(['/campaign/' + x.data[0].id + '/campaign-upload']);
                        }, 500);
                    },
                    reject: () => {
                        this.confirmationService.close();
                    }
                });
            }
        })

    }

    sendToApiBuild(rowData: IBaseCampaignDto, apiStatus: string) {
        this.confirmationService.confirm({
            header: 'Confirmation',
            key: 'changeStatus',
            message:
                'This action will initiate campaign API build. Do you want to continue?',
            rejectVisible: true,
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: async () => {
                let isDateDiff: boolean = false;
                if(rowData.failedCount > 0 && (rowData.campaignStatusId != eCampaignStatus.Campaign_Live && rowData.campaignStatusId != eCampaignStatus.Campaign_Paused)){
                    let newstartdate = moment(new Date(), 'YYYY-MM-DD 00:00:00').add(2, 'days');

                    if(newstartdate.diff(rowData.endDate) >= 0){
                        this.confirmationService.close();
                        this.messageService.add({ severity: 'error', detail: 'Start date is after end date!' });
                        isDateDiff = true;
                    } 
                    
                    else if(newstartdate.diff(rowData.startDate) >= 0){
                        rowData.startDate = newstartdate.utc().startOf('day').toDate();
                        if(new Date(rowData.creatives.find(x => x.creativeGroup == 1 && x.flightEndDate != null)?.flightEndDate) > rowData.startDate)
                            rowData.creatives.filter(x => x.creativeGroup == 1).forEach(x => {
                                x.flightStartDate = rowData.startDate;
                            });
                        else if(new Date(rowData.creatives.find(x => x.creativeGroup == 1 && x.flightEndDate != null)?.flightEndDate) <= rowData.startDate)
                        {
                            this.confirmationService.close();
                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign start date is changed and set to start in next 2 days. Creative flight dates does not correspond to new Campaign start date. Please adjust creative flight dates and try again.', life: 5000 });
                            isDateDiff = true;
                        }
                    }
                }

                if(!isDateDiff){
                    rowData.statusId = eCampaignStatus.Sent_to_API_Build;
                    rowData.dspBuildIndicator = apiStatus;
                    this.pageLoader.showPageLoader();
                    this.campaignController.SaveCampaign(rowData).subscribe(
                        res => {
                            this.pageLoader.hidePageLoader();
                            this.composeGridColumns();
                            this.updateGrid();

                            if(res.statusId == rowData.statusId){
                                this.messageService.add({ severity: 'success', detail: 'Campaign is successfully sent to API build' });
                            }
                            else{
                                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign was not send to API build. Please try again.', life: 5000 });
                            }
                          
                            this.confirmationService.close();
                        },
                        err => {
                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign was not send to API build. Status of campaign is not changed. Please try again.', life: 5000 });
                        }
                        
                    )
                }
                
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }
    initTraffickingGrid(rowData: IBaseCampaignDto) {
        this.confirmationService.confirm({
            header: 'Confirmation',
            key: 'initTrafficking',
            message:
                'This action will initiate trafficking grid. Do you want to continue?',
            rejectVisible: true,
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: async () => {
                rowData.isTraffickingGridInitiated = true;
                this.pageLoader.showPageLoader();
                this.campaignController.SaveCampaign(rowData).subscribe(x => {
                    this.pageLoader.hidePageLoader();

                    this.composeGridColumns();

                    this.messageService.add({ severity: 'success', detail: 'Trafficking grid is successfully initiated' });
                    this.confirmationService.close();
                })
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }
    changeStatus(rowData: IBaseCampaignDto) {
        this.confirmationService.confirm({
            header: 'Confirmation',
            key: 'pauseLiveCampaign',
            message:
                rowData.campaignStatusId == eCampaignStatus.Campaign_Live ? 'This action will pause campaign. Do you want to continue?' : 'This action will change campaign status to Live. Do you want to continue?',
            rejectVisible: true,
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: async () => {
                rowData.statusId = rowData.campaignStatusId == eCampaignStatus.Live_365_Update ? eCampaignStatus.Live_365_Update : rowData.statusId;
                rowData.campaignStatusId = rowData.campaignStatusId == eCampaignStatus.Campaign_Live ? eCampaignStatus.Campaign_Paused : eCampaignStatus.Campaign_Live;
                this.pageLoader.showPageLoader();
                this.campaignController.SaveCampaign(rowData).subscribe( res => {

                    this.pageLoader.hidePageLoader();
                    this.composeGridColumns();
                    this.updateGrid();
                    if(res.campaignStatusId == rowData.campaignStatusId){
                        this.messageService.add({ severity: 'success', detail: rowData.campaignStatusId == eCampaignStatus.Campaign_Live ? 'Campaign is Live now' : 'Campaign is paused successfully' });
                    }
                    else {
                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Status of campaign is not changed. Please try again.', life: 5000 });
                    }
                    this.confirmationService.close();


                },
                err => {
                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Status of campaign is not changed. Please try again.', life: 5000 });
                })
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    getInventoryItemTooltip(rowData: IBaseCampaignDto) {
        let tooltipString = '';
        rowData.campaignInventoryItems.forEach(x => {
            tooltipString += (x.inventoryItem != null ? x.inventoryItem.name :
                (x.inventoryItemNew != null ? x.inventoryItemNew.name :
                    (x.inventoryItemOAS != null ? x.inventoryItemOAS.name : 
                        (x.inventoryItemSocial != null ? x.inventoryItemSocial.name : x.inventoryItemContent.name)))) + '\n'
        });
        return tooltipString;
    }

    remove(item) {
        this.confirmationService.confirm({
            header: 'Confirmation',
            key: 'campaignDelete',
            message:
                'Are you sure you want to delete Campaign?',
            rejectVisible: true,
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: async () => {
                item.entityStatus = eEntityStatus.Deleted;
                this.campaignController.SaveCampaign(item).subscribe(x => {
                    this.composeGridColumns();
                    this.confirmationService.close();
                });
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    checkRow(rowData, event){
        this.campaignBulkSettings = {} as ICampaignBulkSettingsDto;
            if (event.checked) {

                this.campaignBulkSettings.campaignId = rowData.id;
                this.campaignBulkSettings.dealId = rowData.dealId;
                this.campaignBulkSettings.regionId = rowData.regionId;

                this.selectedCampaigns.push(this.campaignBulkSettings);
            } else {
                this.selectedCampaigns = this.selectedCampaigns.filter(
                    item => item.campaignId != rowData.id
                );
            }

        this.selectedCampaignsEmmiter.emit({selectedCampaigns: this.selectedCampaigns, isAllRowsChecked: this.isAllRowsChecked});
    }

    checkAllRows(event){
      this.isAllRowsChecked = event.checked;  
      if(event.checked){
        let options = this.options;

        options.select = ['Id', 'RegionId', 'DealId', 'CampaignJson', 'DV360AdvertiserId', 'TTDAdvertiserId'];

        let param = this.composeODataQuery(options);

        this.campaignController
            .GetAllCampaigns(param)
            .subscribe(x => {
                x.data.forEach(element => {
                    this.campaignBulkSettings = {} as ICampaignBulkSettingsDto;
                    this.campaignBulkSettings.campaignId = element.id;
                    this.campaignBulkSettings.dealId = element.dealId;
                    this.campaignBulkSettings.regionId = element.regionId;                   
                    this.selectedCampaigns.push(this.campaignBulkSettings);
                });
            },
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
            });

      }
      else{
          this.selectedCampaigns = [];
      }
      this.selectedCampaignsEmmiter.emit({selectedCampaigns: this.selectedCampaigns, isAllRowsChecked: this.isAllRowsChecked});

    }

    checkValue(rowData){
        if(this.isAllRowsChecked){
            return true;
        }
        else{
            if (this.selectedCampaigns.find(x => x.campaignId == rowData.id))
            return true;
        else
            return false;
        }
    }

    composeODataQuery(options) {
        const queryBuilder = new QueryBuilder()

        if (!!options.filter) {
            if (typeof options.filter === 'string') {
                const filterComposition = options.filter;

                queryBuilder.filter(filter =>
                    filter.filterPhrase(filterComposition)
                );
            } else if (
                typeof options.filter !== 'string' &&
                options.filter.definition === eFilterDefiniton.Equals
            ) {
                const filterComposition = options.filter;

                queryBuilder.filter(filter =>
                    filter.filterExpression(
                        filterComposition.field,
                        'eq',
                        filterComposition.value
                    )
                );
            } else if (
                typeof options.filter !== 'string' &&
                options.filter.definition === eFilterDefiniton.Contains
            ) {
                const filterComposition = options.filter;

                queryBuilder.filter(filter =>
                    filter.filterPhrase(
                        `contains(${filterComposition.field}, '${
                        filterComposition.value
                        }')`
                    )
                );
            }

            queryBuilder.select(options.select);

            return queryBuilder.toQuery();
        }
    }

    resetSelectedCampaign(){
        this.selectedCampaigns = [];
    }

    setParameters(event){
            this.sortField = event.sortField === undefined ? '' : event.sortField;
            this.sortOrder = event.sortOrder === undefined ? '' : event.sortOrder;
            this.skipParam = event.skip;
            this.takeParam = event.take;
            this.pageLoader.showPageLoader();
    
            this.campaignController.GetCampaignGroupedBySponsor(this.clientNameFilter ? this.clientNameFilter : this.currentAccount[0].id, this.fiscalYearFilter.toString(), this.searchParameterSponsor, this.sortField, this.sortOrder, '', this.skipParam, this.takeParam).pipe(take(1)).subscribe(
                result => {
                    this.options.data = result;
                    this.GridComponent.value = this.options.data;
                    this.pageLoader.hidePageLoader();
                },
                error => {}
            );
    }

    searchGroupedCampaigns(event){
            this.searchParameterSponsor = event;
    
            this.campaignController.GetCampaignGroupedBySponsor(this.clientNameFilter ? this.clientNameFilter : this.currentAccount[0].id, this.fiscalYearFilter.toString(), event, this.sortField, this.sortOrder, '', 0, this.takeParam).pipe(take(1)).subscribe(
                result => {
                    this.options.data = result;
                    this.GridComponent.value = this.options.data;
                    this.GridComponent.setPage(0);
                    this.pageLoader.hidePageLoader();
                },
                error => {}
            );
    }

    setSponsor(sponsor, dealId, event: Event){
        event.preventDefault();
        this.selectedSponsor = sponsor;
        localStorage.setItem('sponsor', sponsor);
        this.dealId = dealId;
        this.updateBreadCrumb.emit(sponsor);
        this.composeGridColumns();
    }

    resetGroupedCampaigns(){
        let url: string = this.route.url.substring(0, this.route.url.indexOf("fan365/"));
        this.route.navigateByUrl(url);

        localStorage.removeItem('sponsor');
        this.selectedSponsor = '';
        this.dealId = '';
        this.updateBreadCrumb.emit('');
        this.composeGroupedGrid();
    }

    transferData(rowData: IBaseCampaignDto){
        this.campaignTransferDataDialog.openDialog(rowData);
    }

    fillSplitButtonMenu(rowData: IBaseCampaignDto){
        let latestApiReporting = rowData.apiReportings.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())[0];
        this.splitButtonMenu = [
            {
                label: 'Edit', 
                icon: 'fa fa-edit',
                command: () => this.openEditDialog(rowData),
                visible: rowData.campaignStatusId == this.enumCampaignStatus.Campaign_Draft
            },
            {
                label: 'Remove', 
                icon: 'fa fa-trash',
                command: () => this.remove(rowData),
                visible: rowData.campaignStatusId == this.enumCampaignStatus.Campaign_Draft
            },
            {
                label: 'Send to API Build (TTD)', 
                icon: 'ttd-icon',
                command: () => this.sendToApiBuild(rowData, 'TTD'),
                visible: !rowData.account.automaticApiBuild 
                && (rowData.campaignStatusId == this.enumCampaignStatus.Assets_Approved || rowData.statusId == this.enumCampaignStatus.Assets_Approved) 
                && rowData.statusId != this.enumCampaignStatus.Sent_to_API_Build && rowData.statusId != this.enumCampaignStatus.Build_Success && rowData.failedCount < 5
                && (this.currentUser.userRole == this.enumUserRole.Media_Activation || this.currentUser.userRole == this.enumUserRole.Admin)
            },
            {
                label: 'Send to API Build (DV360)', 
                icon: 'dv360-icon',
                command: () => this.sendToApiBuild(rowData, 'DV360'),
                visible: !rowData.account.automaticApiBuild && (rowData.campaignStatusId == this.enumCampaignStatus.Assets_Approved || rowData.statusId == this.enumCampaignStatus.Assets_Approved) 
                && rowData.statusId != this.enumCampaignStatus.Sent_to_API_Build && rowData.statusId != this.enumCampaignStatus.Build_Success 
                && rowData.failedCount < 5 && (this.currentUser.userRole == this.enumUserRole.Media_Activation || this.currentUser.userRole == this.enumUserRole.Admin)
            },
            {
                label: 'Go Live', 
                icon: 'fa fa-paper-plane',
                command: () => this.changeStatus(rowData),
                visible: (rowData.statusId == this.enumCampaignStatus.Sent_to_API_Build || rowData.statusId == this.enumCampaignStatus.Build_Success) && (this.currentUser.userRole == this.enumUserRole.Media_Activation || this.currentUser.userRole == this.enumUserRole.Admin)
            },
            {
                label: 'Transfer Data', 
                icon: 'fa fa-retweet',
                command: () => this.transferData(rowData),
                visible: (rowData.campaignType == eAccountType.OAS || rowData.campaignType == eAccountType.FAN365) && (rowData.campaignStatusId == this.enumCampaignStatus.Live_365_Update 
                || rowData.statusId == this.enumCampaignStatus.Live_365_Update 
                || (rowData.campaignStatusId == this.enumCampaignStatus.Campaign_Ended && latestApiReporting.campaignStatusIdNew == this.enumCampaignStatus.Campaign_Ended && latestApiReporting.campaignStatusIdOld == this.enumCampaignStatus.Live_365_Update))
                && rowData.entityStatus == eEntityStatus.Active
                && (!this.currentUser.permissions.isClient || this.currentUser.userRole == this.enumUserRole.LIMGC_AdOps_Admin || this.currentUser.userRole == this.enumUserRole.LIMGCSocialAdOps)
    
            }
        ];
        return this.splitButtonMenu;
    }

    updateGrid(){
        this.applyFilters.emit();
        this.gridList = [];
    }

}


